import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const CREATE_REQUEST = 'realhub/publicationBookingItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/publicationBookingItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/publicationBookingItem/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/publicationBookingItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/publicationBookingItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/publicationBookingItem/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/publicationBookingItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/publicationBookingItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/publicationBookingItem/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createPublicationBookingItem(item, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      publication_booking_item: item,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    // Encode Params
    const promise = api(`/agency/publication_bookings/${item.publication_booking_id}/items.json`, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updatePublicationBookingItem(item, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      publication_booking_item: item,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { id, publication_booking_id } = item
    const promise = api(`/agency/publication_bookings/${publication_booking_id}/items/${id}.json`, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback){
          callback(json)
        }

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deletePublicationBookingItem(item, options, callback){
  const config = {
    method: 'DELETE',
    body: JSON.stringify({
      publication_booking_item: item,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    // Encode Params
    const url = `/agency/publication_bookings/${item.publication_booking_id}/items/${item.id}.json`
    const promise = api(url, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

/*
 * Important
 *
 * Instead of deleting the item it just removes its booking id reference
 * so that the deletion can be confirmed via the publication API
 * If an item has been confirmed by the publication it must be removed and not deleted.
 *
 */

export function removePublicationBookingItem(item, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      publication_booking_item: item,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    // Encode Params
    const url = `/agency/publication_bookings/${item.publication_booking_id}/items/${item.id}/remove.json`
    const promise = api(url, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

export function cancelPublicationBookingItem(item, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const url = `/agency/publication_bookings/${item.previous_booking_id}/items/${item.id}/cancel.json`
    const promise = api(url, {}, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case UPDATE_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      })
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        errors: [],
      })
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        errors: action.errors,
      })
    case DELETE_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      })
    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
        errors: [],
      })
    case DELETE_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
