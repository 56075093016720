import { normalize } from 'normalizr'
import { Schemas } from '../../../shared/redux/schema'
import { updateEntities } from '../../../shared/redux/entity'
import { encodeParams } from '../../../shared/functions/utilities'

const FETCH_REQUEST = 'realhub/templatePageItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/templatePageItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/templatePageItem/FETCH_FAILURE'
const SELECT_PAGE_ITEM = 'realhub/templatePageItem/SELECT_ITEM'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  selected: {},
  loaded: false,
  loading: false,
  loadedItemsForTemplatePageIds: [],
  errors: []
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
    loading: true
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    loadedItemsForTemplatePageIds: payload.loadedItemsForTemplatePageIds,
    errors: [],
    result: payload.result
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors: errors
  }
}

export function selectTemplatePageItem(templatePageItem){
  return (dispatch, getState) => {
    let state = getState();
    let templatePageItems = state.entities.templatePageItems;
    let selectedId = state.templatePageItems.selectedId;
    let selected = state.templatePageItems.selected;

    // Only update state if deselecting or if another item is not currently selected
    if(!templatePageItem.id || !selected.id){
      dispatch({
        type: SELECT_PAGE_ITEM,
        selectedId: templatePageItem.artwork_data_key,
        selected: templatePageItem.id ? Object.assign({}, templatePageItem) : {}
      })
    } 
    // Just keep the old item selected
    else {
      dispatch({
        type: SELECT_PAGE_ITEM,
        selectedId: selectedId,
        selected: Object.assign({}, selected)
      })
    }
  }
}

// Action Creators
export function loadTemplatePageItems(options){
  let config = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(fetchRequest());

    // Encode Params
    let params = options ? `?${encodeParams(options)}` : '';
    let promise = fetch(`/agency/template_pages/${options.template_page_id}/items.json${params}`, config)
      .then(response => {
        return response.json().then( json => ({ json, response }) )
      })
      .then(({ json, response}) => {
        if(!response.ok){
          return Promise.reject(json);
        }

        let loadedItemsForTemplatePageIds = getState().templatePageItems.loadedItemsForTemplatePageIds ? getState().templatePageItems.loadedItemsForTemplatePageIds.slice() : [];
        if(options.template_page_id){
          loadedItemsForTemplatePageIds.push(options.template_page_id);
        }

        let normalizedJson = normalize(json, Schemas.TEMPLATE_PAGE_ITEM_ARRAY);
        dispatch(updateEntities(normalizedJson));
        dispatch(fetchSuccess({
          result: normalizedJson.result,
          loadedItemsForTemplatePageIds: loadedItemsForTemplatePageIds
        }));
      })
      .catch(json => {
        let errors = json.errors ? json.errors : ["Something went wrong..."];
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch(action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        loadedItemsForTemplatePageIds: action.loadedItemsForTemplatePageIds,
        result: action.result,
        errors: []
      });
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors
      });
    case SELECT_PAGE_ITEM:
      return Object.assign({}, state, {
        selectedId: action.selectedId,
        selected: action.selected
      });
    default:
      return state;
  }
}