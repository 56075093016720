const CHANGE_FILTER = 'realhub/agencyManager/agency/templatePageFilters/CHANGE_FILTERS'
const CHANGE_MULTIPLE_FILTERS = 'realhub/agencyManager/agency/templatePageFilters/CHANGE_MULTIPLE_FILTERS'

// Initial State
const initialState = {
  filterProviderId: 'all',
  filterServiceId: 'all',
  filterServiceProductId: 'all',
  filterString: '',
  filterTagId: 'all',
  filterTemplatePageCategoryId: 'all',
}

// Actions
export function changeFilter(name, value){
  return {
    type: CHANGE_FILTER,
    name,
    value,
  }
}

export function changeMultipleFilters(filters = {}){
  return {
    type: CHANGE_MULTIPLE_FILTERS,
    filters,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, filters } = action

  switch (type){
    case CHANGE_FILTER:{
      return {
        ...state,
        [action.name]: action.value,
      }
    }
    case CHANGE_MULTIPLE_FILTERS:{
      return {
        ...state,
        ...filters,
      }
    }
    default:
      return state
  }
}
