import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'
import { updateInvoiceTotal } from './invoice'

const CREATE_REQUEST = 'realhub/invoiceItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/invoiceItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/invoiceItem/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/invoiceItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/invoiceItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/invoiceItem/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/invoiceItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/invoiceItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/invoiceItem/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

const handleError = errors => errors || ['Something went wrong']

export function createInvoiceItem(invoiceItem, options, callback){
  const { invoice_id } = invoiceItem

  const config = {
    method: 'POST',
    body: JSON.stringify({
      invoice_item: invoiceItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/invoices/${invoice_id}/items.json`, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Invoice Total
        if (json.invoice_total){
          dispatch(updateInvoiceTotal(json.invoice_id, json.invoice_total))
        }

        const normalizedJson = normalize(json, Schemas.INVOICE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateInvoiceItem(invoiceItem, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      invoice_item: invoiceItem,
    }),
  }

  const { id, invoice_id } = invoiceItem

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/invoices/${invoice_id}/items/${id}.json`, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Invoice Total
        if (json.invoice_total){
          dispatch(updateInvoiceTotal(json.invoice_id, json.invoice_total))
        }

        const normalizedJson = normalize(json, Schemas.INVOICE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteInvoiceItem(invoiceItem, options){
  const config = {
    method: 'DELETE',
  }

  const { id, invoice_id } = invoiceItem

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/invoices/${invoice_id}/items/${id}.json`, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        let resultArray = getState().invoiceItems.result ? getState().invoiceItems.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray = resultArray.splice(index, 1)
        }

        // Update Invoice Total
        if (json.invoice_total){
          dispatch(updateInvoiceTotal(json.invoice_id, json.invoice_total))
        }

        const normalizedJson = normalize(json, Schemas.INVOICE_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: resultArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        result: action.result,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
