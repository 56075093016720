import api from 'api'

const CHANGE_FILTER = 'realhub/agencyManager/accounting/invoices/CHANGE_FILTER'
const FETCH_FAILURE = 'realhub/agencyManager/accounting/invoices/FETCH_FAILURE'
const FETCH_REQUEST = 'realhub/agencyManager/accounting/invoices/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/agencyManager/accounting/invoices/FETCH_SUCCESS'
const UPDATE_INTEGRATION_DATA = 'realhub/agencyManager/accounting/invoices/UPDATE_INTEGRATION_DATA'
const UPDATE_XERO_INVOICES = 'realhub/agencyManager/accounting/invoices/UPDATE_XERO_INVOICES'

// Initial State
const initialState = {
  filterApproved: 'true',
  filterCampaignAddress: '',
  filterEndDate: null,
  filterLimit: 100,
  filterPaid: 'all',
  filterStartDate: null,
  filterString: '',
  filterSyncStatus: 'all',
  filterType: 'creditor',
  integrationData: {},
  loading: false,
  xeroInvoices: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
    loading: true,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
    loading: false,
  }
}

export function fetchFailure(){
  return {
    type: FETCH_FAILURE,
    loading: false,
  }
}

export function updateIntegrationData(data){
  return {
    type: UPDATE_INTEGRATION_DATA,
    data,
  }
}

export function updateXeroInvoices(data){
  return {
    type: UPDATE_XERO_INVOICES,
    data,
  }
}

export function changeFilter(name, value){
  return {
    type: CHANGE_FILTER,
    name,
    value,
  }
}

export function loadIntegrationData(accountingPlatformApi, options = {}){
  return (dispatch) => {
    const promise = api(`/integrations/${accountingPlatformApi || 'xero'}/data.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateIntegrationData(json.integration))

        return { success: true, result: json }
      })
      .catch(() => {
        const data = {}
        dispatch(updateIntegrationData(data))
        return { success: false, errors: ['Could not load integration data.'] }
      })
    return promise
  }
}

export function searchExternalInvoices(options, callback){
  return (dispatch) => {
    dispatch(fetchRequest())
    const promise = api('/agency/invoices/search_external_invoices.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateXeroInvoices(json.data))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch(() => {
        const data = []
        dispatch(updateXeroInvoices(data))
        return { success: false, errors: ['Could not fetch external invoices.'] }
      })

    return promise
  }
}

export function disconnectFromIntegration(integrationId){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    const promise = api(`/integrations/${integrationId}/disconnect.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateIntegrationData(json.integration))

        return { success: true, result: json }
      })
      .catch(() => {
        const data = {}
        dispatch(updateIntegrationData(data))

        return { success: false, errors: ['Could not load integration data.'] }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    case UPDATE_INTEGRATION_DATA:
      return { ...state, integrationData: action.data }
    case UPDATE_XERO_INVOICES:
      return { ...state, xeroInvoices: action.data }
    case CHANGE_FILTER:
      return {
        ...state,
        ...{ [action.name]: action.value },
      }
    default:
      return state
  }
}
