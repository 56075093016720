import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/adCampaign/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/adCampaign/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/adCampaign/FETCH_FAILURE'

const SELECT_AD_CAMPAIGN = 'realhub/adCampaign/SELECT_AD_CAMPAIGN'


// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
  selectedId: null,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function selectAdCampaign(adCampaign = {}){
  return (dispatch) => {
    dispatch({
      type: SELECT_AD_CAMPAIGN,
      selectedId: adCampaign.id,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadAdCampaigns(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/ad_campaigns.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AD_CAMPAIGN_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case SELECT_AD_CAMPAIGN:
      return {
        ...state,
        selectedId: action.selectedId,
      }
    default:
      return state
  }
}
