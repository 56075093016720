const CHANGE_ATTRIBUTE = 'realhub/agencyMarketing/orderBuilder/CHANGE_ATTRIBUTE'

// Initial State
const initialState = {
  campaignTitle: '',
  filterString: '',
  selectedAgencyId: '',
  selectedAgentIds: [],
  selectedOptions: [],
  selectedProductId: null,
  selectedProviderId: null,
  selectedQuantityId: null,
  selectedTagId: null,
  selectedTemplatePageId: null,
}

// Actions
export function changeAttribute(name, value){
  return {
    type: CHANGE_ATTRIBUTE,
    name,
    value,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_ATTRIBUTE:{
      const change = {}
      change[action.name] = action.value
      return Object.assign({}, state, change)
    }
    default:
      return state
  }
}
