import KeenAnalysis from 'keen-analysis'

const FETCH_REQUEST = 'realhub/analytics/eventAnalysis/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/analytics/eventAnalysis/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/analytics/eventAnalysis/FETCH_FAILURE'
const SET_CLIENT = 'realhub/analytics/eventAnalysis/SET_CLIENT'

// Initial State
const initialState = {
  client: null,
  results: {},
  loading: false,
  processedRequests: [],
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loading: true,
    queryKey: payload.queryKey,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    errors: [],
    queryKey: payload.queryKey,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loading: false,
    errors,
  }
}

export function setClient(client){
  return {
    type: SET_CLIENT,
    client,
  }
}

// Action Creators
export function initializeClient(config){
  // Setup Client
  const client = new KeenAnalysis(config)

  return (dispatch) => {
    dispatch(setClient(client))

    return client
  }
}

export function loadQuery(queryKey, queryParams, options = {}){
  return (dispatch, getState) => {
    const { client } = getState().analytics.eventAnalysis
    if (client){
      dispatch(fetchRequest({ queryKey }))

      const promise = options.multiAnalysis ? client.query('multi_analysis', queryParams) : client.query(queryParams)

      promise
        .then((json) => {
          dispatch(fetchSuccess({ queryKey, result: json.result }))

          return json
        })
        .catch((error) => {
          const errors = [error]
          dispatch(fetchFailure(errors))
        })

      return promise
    }

    return null
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case SET_CLIENT:
      return {
        ...state,
        client: action.client,
      }
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        processedRequests: [...state.processedRequests, ...[action.queryKey]],
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        results: {
          ...state.results,
          [action.queryKey]: action.result,
        },
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
