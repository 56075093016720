import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/image/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/image/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/image/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/image/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/image/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/image/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/image/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/image/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/image/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/image/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/image/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/image/DELETE_FAILURE'

const SELECT_IMAGE = 'realhub/image/SELECT_IMAGE'

const ADD_IMAGE = 'realhub/image/ADD_IMAGE'
const DELETE_IMAGE = 'realhub/image/DELETE_IMAGE'

// Initial State
const initialState = {
  deleting: false,
  errors: [],
  loadedIds: [],
  loadedImagesForEntities: [],
  loading: false,
  result: [],
  selectedId: null,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
    loadedImagesForEntities: payload.loadedImagesForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    errors: [],
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectImageId(imageId){
  return (dispatch) => {
    dispatch({
      type: SELECT_IMAGE,
      selectedId: imageId,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadImages(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/images.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.IMAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadImageIds(imageIds, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({ image_ids: imageIds }),
  }

  return (dispatch, getState) => {
    // Set Loading
    let loadedIds = getState().images.loadedIds ? getState().images.loadedIds.slice() : []
    loadedIds = loadedIds.concat(imageIds)
    dispatch(fetchRequest({ loadedIds }))

    const promise = api('/agency/images/bulk_load.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.IMAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadEntityImages(entityType, entityId, options, callback){
  let url
  switch (entityType){
    case 'CampaignComparable':
      url = `/agency/campaign_comparables/${entityId}/images.json`
      break
    case 'User':
      url = `/agency/users/${entityId}/images.json`
      break
    case 'ImageLibrary':
      url = `/agency/image_libraries/${entityId}/images.json`
      break
    default:
      url = `/agency/campaigns/${entityId}/images.json`
      break
  }

  const entityKey = `${entityType}${entityId}`

  return (dispatch, getState) => {
    // Set Loading
    const loadedImagesForEntities = getState().images.loadedImagesForEntities
      ? getState().images.loadedImagesForEntities.slice()
      : []
    const index = loadedImagesForEntities.indexOf(entityKey)
    if (index === -1){
      loadedImagesForEntities.push(entityKey)
    }
    dispatch(fetchRequest({ loadedImagesForEntities }))

    const promise = api(url, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.IMAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadImage(imageId, options){
  return (dispatch, getState) => {
    const { images } = getState()
    const loadedIds = images.loadedIds ? getState().images.loadedIds.slice() : []

    if (!loadedIds.includes(imageId)){
      loadedIds.push(imageId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/images/${imageId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.IMAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function addImage(image){
  const normalizedJson = normalize(image, Schemas.IMAGE)

  return (dispatch) => {
    dispatch(updateEntities(normalizedJson))
  }
}

export function deleteImage(image){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/images/${image.id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(image, Schemas.IMAGE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess(json))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateImage(image, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      image,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/images/${image.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.IMAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateImageSortOrder(imageIds, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sort_order: imageIds,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api('/agency/images/update_sort_order.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.IMAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function bulkDelete(imageIds, options, callback){
  const config = {
    method: 'DELETE',
    body: JSON.stringify({
      image_ids: imageIds,
    }),
  }

  return (dispatch, getState) => {
    const state = getState()

    // Set Loading
    dispatch(deleteRequest())

    const promise = api('/agency/images/bulk_delete.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const { images } = state.entities

        json.ids.forEach((imageId) => {
          const image = images[imageId]
          const normalizedJson = normalize(image, Schemas.IMAGE)
          dispatch(deleteEntity(normalizedJson))
        })
        dispatch(deleteSuccess(json))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function bulkUpdate(imageIds, updatedParams, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      image: updatedParams,
      image_ids: imageIds,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api('/agency/images/bulk_update.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.IMAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function apiExportImages(imageIds, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      image_ids: imageIds,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/images/api_export.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(createSuccess(json))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedImagesForEntities: action.loadedImagesForEntities || state.loadedImagesForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case SELECT_IMAGE:
      return { ...state, selectedId: action.selectedId }
    case ADD_IMAGE:
      return { ...state, ...action }
    case DELETE_IMAGE:
      return { ...state, ...action }
    default:
      return state
  }
}
