import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

import { deleteRendersForArtworkDataKeys } from './artworkPageItem'
import { changeSetting as changeEditPageSetting } from './artworkBuilder/editPage'

const FETCH_REQUEST = 'realhub/artwork/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/artwork/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/artwork/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/artwork/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/artwork/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/artwork/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/artwork/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/artwork/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/artwork/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/artwork/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/artwork/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/artwork/DELETE_FAILURE'

const SELECT_ARTWORK = 'realhub/artwork/SELECT_ARTWORK'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  loaded: false,
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(payload = {}){
  return {
    type: UPDATE_SUCCESS,
    result: payload.result,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectArtwork(artwork){
  return (dispatch, getState) => {
    const state = getState()
    const { artworks } = state.entities

    const selectedArtwork = artworks[artwork.id]

    dispatch({
      type: SELECT_ARTWORK,
      selectedId: artwork.id,
      selected: selectedArtwork,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function addArtwork(artwork){
  const normalizedJson = normalize(artwork, Schemas.ARTWORK)

  return (dispatch) => {
    dispatch(updateEntities(normalizedJson))
  }
}

export function hydrateArtwork(artworkJson){
  return (dispatch) => {
    dispatch(fetchRequest())

    if (artworkJson.id){
      const normalizedJson = normalize(artworkJson, Schemas.ARTWORK)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess(normalizedJson))
    } else {
      dispatch(fetchFailure(['Could not hydrate undefined artwork.']))
    }
  }
}

export function loadArtworks(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/artworks.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createArtwork(orderItem, templatePage, options = {}, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      artwork: {
        campaign_id: orderItem.campaign_id,
        template_page_category_id: options.template_page_category_id || null,
      },
      artwork_page: {
        template_page_id: templatePage.id,
      },
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/artworks.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().artworks.result ? getState().artworks.result.slice() : []
        resultArray.push(json.id)

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function createArtworkWithCampaign(body, options = {}, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      ...body,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/artworks/create_with_campaign.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createArtworkFromSaved(orderItem, savedArtwork, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      order_item_id: orderItem.id,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/saved_artworks/${savedArtwork.id}/restore_as_artwork.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().artworks.result ? getState().artworks.result.slice() : []
        resultArray.push(json.id)

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function createArtworkFromBackup(orderItem, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const url = `/agency/order_items/${orderItem.id}/artworks/restore_from_backup.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createArtworkFromSavedPage(orderItem, savedArtworkPage, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      order_item_id: orderItem.id,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const url = `/agency/saved_artwork_pages/${savedArtworkPage.id}/restore_as_artwork.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().artworks.result ? getState().artworks.result.slice() : []
        resultArray.push(json.id)

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function deleteArtwork(artwork, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/order_items/${artwork.order_item_id}/artworks/${artwork.id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

export function approveArtwork(artwork, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const url = `/agency/order_items/${artwork.order_item_id}/artworks/${artwork.id}/approve.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function unapproveArtwork(artwork, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(
      `/agency/order_items/${artwork.order_item_id}/artworks/${artwork.id}/unapprove.json`,
      options,
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function buildArtworkPdf(artwork, options, callback){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const url = `/agency/artworks/${artwork.id}/build_pdf.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function imposeArtworkPdf(artwork, options, callback){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const url = `/agency/artworks/${artwork.id}/impose_pdf.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function renderArtworkPreview(artwork, options, callback){
  const renderUrl = artwork.links ? artwork.links.render_preview : null

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(renderUrl, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const updatedArtwork = { ...artwork, preview: json.previews }

        const normalizedJson = normalize(updatedArtwork, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(updatedArtwork)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function loadArtworkDataChanges(artwork, options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api(`/agency/artworks/${artwork.id}/data_changes.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function patchArtworkData(artwork, changes, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      changes: JSON.stringify(changes),
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/artworks/${artwork.id}/patch_data.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Clear Renders
        if (json.pages){
          json.pages.forEach((page) => {
            if (page.updated_data_keys){
              dispatch(deleteRendersForArtworkDataKeys(page, page.updated_data_keys))
            }
          })
        }

        // Add changes to appliedPatches array
        const appliedPatches = [...getState().artworkBuilder.editPage.appliedDataPatches]
        dispatch(changeEditPageSetting('appliedDataPatches', appliedPatches.concat(changes)))

        const normalizedJson = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result || state.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case SELECT_ARTWORK:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
