import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/campaignItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaignItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaignItem/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/campaignItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignItem/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/campaignItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaignItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaignItem/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaignItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignItem/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loadedForEntities: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCampaignItems(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api(`/agency/campaigns/${options.campaign_id}/items.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadEntityCampaignItems(entityKey, options){
  return (dispatch, getState) => {
    const { campaignItems } = getState()
    const loadedForEntities = campaignItems.loadedForEntities ? campaignItems.loadedForEntities.slice() : []
    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api(`/agency/campaigns/${options.campaign_id}/items.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createCampaignItem(campaignItem, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_item: campaignItem,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/campaigns/${campaignItem.campaign_id}/items.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().campaignItems.result ? getState().campaignItems.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCampaignItem(campaignItem, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign_item: campaignItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaigns/${campaignItem.campaign_id}/items/${campaignItem.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteCampaignItem(campaignItem, options, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/campaigns/${campaignItem.campaign_id}/items/${campaignItem.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        let resultArray = getState().campaignItems.result ? getState().campaignItems.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray = resultArray.splice(index, 1)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
