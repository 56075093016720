import api from 'api'

const FETCH_REQUEST = 'realhub/artworkBuilder/imageSources/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/artworkBuilder/imageSources/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/artworkBuilder/imageSources/FETCH_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadArtworkImageSources(artworkId, options, callback){
  return (dispatch) => {
    dispatch(fetchRequest())

    api(`/agency/artworks/${artworkId}/image_sources.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess(json))
        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { payload, type, errors } = action

  switch (type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        result: payload || state.result,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: true, // Setting this to true because we aren't checking errors in builder
        errors,
      })
    default:
      return state
  }
}
