import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const CREATE_REQUEST = 'realhub/attachment/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/attachment/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/attachment/CREATE_FAILURE'

const FETCH_REQUEST = 'realhub/attachment/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/attachment/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/attachment/FETCH_FAILURE'

const UPDATE_REQUEST = 'realhub/attachment/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/attachment/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/attachment/UPDATE_FAILURE'

const DELETE_REQUEST = 'realhub/attachment/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/attachment/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/attachment/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForEntities: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function hydrateAttachment(attachment){
  const normalizedJson = normalize(attachment, Schemas.ATTACHMENT)

  return (dispatch) => {
    dispatch(updateEntities(normalizedJson))
  }
}

export function loadEntityAttachments(entityType, entityId, options){
  let url
  switch (entityType){
    default:
      url = `/agency/campaigns/${entityId}/attachments.json`
      break
  }

  const entityKey = `${entityType}${entityId}`

  return (dispatch, getState) => {
    // Set Loading
    const loadedForEntities = [...getState().attachments.loadedForEntities]
    const index = loadedForEntities.indexOf(entityKey)
    if (index === -1){
      loadedForEntities.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api(url, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createAttachment(attachment, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      attachment,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/attachments.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateAttachment(attachment, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      attachment,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/attachments/${attachment.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function copyToCampaign(orderItem, attachmentIds, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      attachment_ids: attachmentIds,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/attachments/copy_to_campaign.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const addDataJson = json.map((attachment) => {
          const { data } = attachment

          return {
            ...attachment,
            data: { ...data, imported: true },
          }
        })

        const normalizedJson = normalize(addDataJson, Schemas.ATTACHMENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function prepareDownload(orderItem, attachmentIds, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      attachment_ids: attachmentIds,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/attachments/prepare_download.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function loadEntityPendingMedia(entityType, entityId, options){
  let url
  switch (entityType){
    case 'Campaign':
      url = `/agency/campaigns/${entityId}/pending_media.json`
      break
    default:
      url = `/agency/campaigns/${entityId}/pending_media.json`
      break
  }

  const entityKey = `${entityType}${entityId}`

  return (dispatch, getState) => {
    const loadedForEntities = [...getState().attachments.loadedForEntities]
    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api(url, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const normalizedJson = normalize(json, Schemas.ATTACHMENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ loadedForEntities }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function generateAttachmentUrl(attachmentId, options){
  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/attachments/${attachmentId}/generate_attachment_url.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteAttachment(attachment, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/agency/attachments/${attachment.id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const normalizedJson = normalize(json, Schemas.ATTACHMENT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
