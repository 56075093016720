const CHANGE_ZOOM = 'realhub/publicationManager/mediaPlanner/CHANGE_ZOOM'
const UPDATE_REMOVED_ITEMS = 'realhub/publicationManager/mediaPlanner/UPDATE_REMOVED_ITEMS'
const UPDATE_COMPATIBLE_ARTWORK_DATA = 'realhub/publicationManager/mediaPlanner/UPDATE_COMPATIBLE_ARTWORK_DATA'

// Initial State
const initialState = {
  zoom: window.innerWidth < 1200 ? 0.75 : 0.9,
  removedBookingItems: [],
  compatibleArtworkData: {
    artworks: [],
    orderItem: {},
    selectTemplateLink: '',
  },
}

// Actions
export function changeZoom(zoom){
  return {
    type: CHANGE_ZOOM,
    zoom,
  }
}

export function updateRemovedItems(bookingItems){
  return {
    type: UPDATE_REMOVED_ITEMS,
    bookingItems,
  }
}

export function updateCompatibleArtworkData(data){
  return {
    type: UPDATE_COMPATIBLE_ARTWORK_DATA,
    data,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_ZOOM:
      return Object.assign({}, state, {
        zoom: action.zoom,
      })
    case UPDATE_REMOVED_ITEMS:
      return Object.assign({}, state, {
        removedBookingItems: action.bookingItems,
      })
    case UPDATE_COMPATIBLE_ARTWORK_DATA:
      return Object.assign({}, state, {
        compatibleArtworkData: action.data,
      })
    default:
      return state
  }
}
