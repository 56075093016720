import { combineReducers } from 'redux'

import coreLogic from './coreLogic'
import docusign from './docusign'
import domainGroup from './domainGroup'
import youtube from './youtube'

export default combineReducers({
  coreLogic,
  docusign,
  domainGroup,
  youtube,
})
