import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/orderItemTask/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/orderItemTask/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/orderItemTask/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/orderItemTask/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/orderItemTask/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/orderItemTask/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/orderItemTask/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/orderItemTask/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/orderItemTask/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/orderItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/orderItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/orderItem/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  result: [],
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function hydrateOrderItemTask(orderItemTask){
  return (dispatch) => {
    dispatch(fetchRequest())

    if (orderItemTask.id){
      const normalizedJson = normalize(orderItemTask, Schemas.ORDER_ITEM_TASK)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess())

      return { success: true, result: orderItemTask }
    }

    const errors = ['Could not hydrate undefined order item task.']
    dispatch(fetchFailure(errors))
    return { success: false, result: errors }
  }
}

// Action Creators
export function loadOrderItemTasks(options){
  const { entityKey } = options

  return (dispatch, getState) => {
    // Set Loading
    const loadedForKeys = [...getState().orderItemTasks.loadedForKeys]
    if (!loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/agency/order_item_tasks.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ITEM_TASK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = json.errors || ['Something went wrong']
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createOrderItemTask(orderItemTask, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      task: orderItemTask,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/order_items/${orderItemTask.order_item_id}/tasks.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ITEM_TASK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = json.errors || ['Something went wrong']
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateOrderItemTask(orderItemTask, options){
  const { id, order_item_id } = orderItemTask

  const config = {
    method: 'PUT',
    body: JSON.stringify({
      task: orderItemTask,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const promise = api(`/agency/order_items/${order_item_id}/tasks/${id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ITEM_TASK)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = json.errors || ['Something went wrong']
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteOrderItemTask(orderItemTask, options){
  const { id, order_item_id } = orderItemTask

  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/order_items/${order_item_id}/tasks/${id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ITEM_TASK)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = json.errors || ['Something went wrong']
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
