import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const CREATE_REQUEST = 'realhub/subscriber/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/subscriber/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/subscriber/CREATE_FAILURE'
const DELETE_REQUEST = 'realhub/subscriber/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/subscriber/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/subscriber/DELETE_FAILURE'

const initialState = {
  creating: false,
  deleting: false,
  errors: [],
}

// Action
export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Funcations
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createSubscribers(subscribableType, subscribableId, userIds, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      subscribable_type: subscribableType,
      subscribable_id: subscribableId,
      user_ids: userIds,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/subscribers/create_multiple.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.SUBSCRIBER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteSubscriber(subscriber, options){
  const config = {
    method: 'DELETE',
    body: JSON.stringify({
      subscriber,
    }),
  }

  return (dispatch) => {
    const promise = api(`/agency/subscribers/${subscriber.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.SUBSCRIBER)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case DELETE_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      })
    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
        errors: [],
      })
    case DELETE_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
