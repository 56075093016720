import { normalize } from 'normalizr'
import { push } from 'connected-react-router'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

import { addImage, deleteImage } from './image'

const FETCH_REQUEST = 'realhub/user/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/user/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/user/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/user/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/user/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/user/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/user/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/user/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/user/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/user/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/user/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/user/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  selected: {},
  loaded: false,
  loadedIds: [],
  loadedUsersForEntities: [],
  loading: false,
  updating: false,
  creating: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
    loadedUsersForEntities: payload.loadedUsersForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
    errors: [],
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadUsers(options){
  const { entityType, entityId } = options || {}

  return (dispatch, getState) => {
    const loadedUsersForEntities = [...getState().users.loadedUsersForEntities]
    const entityKey = `${entityType}${entityId}`

    if (entityType && entityId && !loadedUsersForEntities.includes(entityKey)){
      loadedUsersForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedUsersForEntities }))

    const promise = api('/agency/users.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.USER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadEntityUsers(entityType, entityId, options){
  let url
  switch (entityType){
    default:
      url = `/agency/campaigns/${entityId}/users.json`
      break
  }

  const entityKey = `${entityType}${entityId}`

  return (dispatch, getState) => {
    const loadedUsersForEntities = getState().users.loadedUsersForEntities
      ? getState().users.loadedUsersForEntities.slice()
      : []
    const index = loadedUsersForEntities.indexOf(entityKey)
    if (index === -1){
      loadedUsersForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedUsersForEntities }))

    const promise = api(url, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.USER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadUser(userId, options){
  return (dispatch, getState) => {
    const loadedIds = [...getState().users.loadedIds]
    if (!loadedIds.includes(userId)){
      loadedIds.push(userId)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/users/${userId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadUserAuthToken(user, options, callback){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/agency/users/${user.id}/auth_token.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createUser(user, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({ user }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/users.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add the user from the user array
        const userArray = getState().users.result ? getState().users.result.slice() : []
        userArray.push(json.id)

        // TODO - Shouldn't be doing this here...
        dispatch(push(`/users/${json.id}/edit`))

        const normalizedJson = normalize(json, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: userArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateUser(user, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({ user }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/users/${user.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteUser(user, options){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/users/${user.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove the user from the user array
        const userArray = getState().users.result ? getState().users.result.slice() : []
        const index = userArray.indexOf(user.id)
        userArray.splice(index, 1)

        // TODO - Shouldn't do this here
        dispatch(push('/users'))

        const normalizedJson = normalize(user, Schemas.USER)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: userArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function addUserImage(image = {}){
  return (dispatch, getState) => {
    const state = getState()

    // Add the image
    dispatch(addImage(image))

    // Add the image ID to the user
    const user = { ...state.entities.users[image.imageable_id] }
    user.images.push(image.id)

    const normalizedJson = normalize(user, Schemas.USER)
    dispatch(updateEntities(normalizedJson))
  }
}

export function deleteUserImage(user, image = {}){
  return (dispatch, getState) => {
    const state = getState()

    // Remove the image from the user
    const userEntity = { ...state.entities.users[user.id] }
    userEntity.images.some((imageId, index) => {
      if (imageId === image.id){
        userEntity.images.splice(index, 1)
        return true
      }
      return false
    })

    // Delete the image
    dispatch(deleteImage(image))

    const normalizedJson = normalize(userEntity, Schemas.USER)
    dispatch(updateEntities(normalizedJson))
  }
}

export function transferCampaigns(user, newAgentId, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({ new_agent_id: newAgentId }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(
      `/agency/agencies/${user.association.id}/agents/${user.actable.id}/transfer_campaigns.json`,
      {},
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        if (callback) callback(user)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function presignUrl(user, redirectUrl, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({ redirect: redirectUrl }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api(`/agency/users/${user.id}/presign_url.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function sendTwoFactorSms(user, options = {}){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/agency/users/${user.id}/send_two_factor_sms.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function validateTwoFactorCode(user, code, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({ otp_code: code }),
  }

  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/agency/users/${user.id}/validate_two_factor_code.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function validateSecureModeToken(user, options = {}){
  const config = {
    method: 'GET',
  }

  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/agency/users/${user.id}/validate_secure_mode_token.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function validateAuthToken(options = {}){
  return () => {
    const promise = api('/agency/users/validate_token.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        return { success: false, result: errors }
      })

    return promise
  }
}

export function generateAuthenticatedAppUrl(user, options = {}){
  const config = {
    method: 'PUT',
  }

  return () => {
    const promise = api(`/agency/users/${user.id}/generate_authenticated_app_url.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedUsersForEntities: action.loadedUsersForEntities || state.loadedUsersForEntities,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
        result: action.result,
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        result: action.result,
        updating: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
