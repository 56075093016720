import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/campaignProposal/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaignProposal/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaignProposal/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/campaignProposal/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignProposal/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignProposal/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/campaignProposal/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaignProposal/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaignProposal/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaignProposal/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignProposal/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignProposal/DELETE_FAILURE'

const SHARE_REQUEST = 'realhub/campaignProposal/SHARE_REQUEST'
const SHARE_SUCCESS = 'realhub/campaignProposal/SHARE_SUCCESS'
const SHARE_FAILURE = 'realhub/campaignProposal/SHARE_FAILURE'

const SELECT_PROPOSAL = 'realhub/campaignProposal/SELECT_PROPOSAL'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  loaded: false,
  loadedForEntities: [],
  loadedIds: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  sharing: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectCampaignProposal(proposal){
  return {
    type: SELECT_PROPOSAL,
    selectedId: proposal.id,
  }
}

export function shareRequest(){
  return {
    type: SHARE_REQUEST,
  }
}

export function shareSuccess(){
  return {
    type: SHARE_SUCCESS,
  }
}

export function shareFailure(errors = []){
  return {
    type: SHARE_FAILURE,
    errors,
  }
}

// HELPER FUNCTIONS
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function hydrateCampaignProposal(json){
  return (dispatch) => {
    dispatch(fetchRequest())

    if (json.id){
      const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess(normalizedJson))
    } else {
      dispatch(fetchFailure(['Could not hydrate undefined proposal.']))
    }
  }
}

export function loadCampaignProposals(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForEntities = [...getState().campaignProposals.loadedForEntities]
    if (entityKey && !loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/campaign_proposals.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadCampaignProposal(campaignProposalId, options, callback){
  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = state.campaignProposals.loadedIds ? state.campaignProposals.loadedIds.slice() : []
    if (!loadedIds.includes(campaignProposalId)){
      loadedIds.push(campaignProposalId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/campaign_proposals/${campaignProposalId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createCampaignProposal(campaignProposal, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_proposal: campaignProposal,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/campaign_proposals.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().campaignProposals.result ? getState().campaignProposals.result.slice() : []
        if (!resultArray.includes(json.id)){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function createProposalWithCampaign(campaignProposal, campaign, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign,
      campaign_proposal: campaignProposal,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/campaign_proposals/create_with_campaign.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createOrUpdateCustomImage(campaignProposal, image, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      image_id: image.id,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(
      `/agency/campaign_proposals/${campaignProposal.id}/create_or_update_custom_image.json`,
      options,
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function resetCustomImages(campaignProposal, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_proposals/${campaignProposal.id}/reset_custom_images.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const { ids } = json

        if (ids && Array.isArray(ids)){
          ids.forEach((id) => {
            const normalizedJson = normalize({ id }, Schemas.ATTACHMENT)
            dispatch(deleteEntity(normalizedJson))
          })

          dispatch(updateSuccess())
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCampaignProposal(campaignProposal, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign_proposal: campaignProposal,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_proposals/${campaignProposal.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteCampaignProposal(campaignProposal, options, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/campaign_proposals/${campaignProposal.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        let resultArray = getState().campaignProposals.result ? getState().campaignProposals.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray = resultArray.splice(index, 1)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: resultArray }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

export function shareCampaignProposal(campaignProposal, mobileNumbers = [], message = '', callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      mobile_numbers: mobileNumbers,
      message,
    }),
  }

  return (dispatch) => {
    dispatch(shareRequest())

    const promise = api(`/agency/campaign_proposals/${campaignProposal.id}/share.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(shareSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(shareFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function acceptCampaignProposal(campaignProposal, options, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_proposals/${campaignProposal.id}/accept.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function buildCampaignProposalPdf(campaignProposal, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_proposals/${campaignProposal.id}/build_pdf.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function duplicateCampaignProposal(campaignProposal, campaign, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign,
      campaign_proposal: {
        digital_template_id: campaignProposal.digital_template_id,
        proposal_type: campaignProposal.proposal_type,
      },
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/campaign_proposals/${campaignProposal.id}/duplicate.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        result: action.result,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case SELECT_PROPOSAL:
      return { ...state, selectedId: action.selectedId }
    case SHARE_REQUEST:
      return { ...state, sharing: true }
    case SHARE_SUCCESS:
      return {
        ...state,
        sharing: false,
        errors: [],
      }
    case SHARE_FAILURE:
      return {
        ...state,
        sharing: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
