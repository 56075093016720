import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'
import pascalToSnakeCase from 'functions/pascalToSnakeCase'

const FETCH_REQUEST = 'realhub/conversation/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/conversation/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/conversation/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/conversation/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/conversation/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/conversation/CREATE_FAILURE'

const SELECT_CONVERSATION = 'realhub/conversation/SELECT_CONVERSATION'

const MARK_REQUEST = 'realhub/conversation/MARK_REQUEST'
const MARK_SUCCESS = 'realhub/conversation/MARK_SUCCESS'
const MARK_FAILURE = 'realhub/conversation/MARK_FAILURE'

const initialState = {
  selectedId: null,
  loading: false,
  loaded: false,
  loadedIds: [],
  loadedForEntities: [],
  loadedForSubjects: [],
  creating: false,
  marking: false,
  errors: [],
}

// Actions

export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
    loadedForSubjects: payload.loadedForSubjects,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function selectConversation(conversation){
  return (dispatch) => {
    dispatch({
      type: SELECT_CONVERSATION,
      selectedId: conversation.id,
    })
  }
}

export function markRequest(){
  return {
    type: MARK_REQUEST,
  }
}

export function markSuccess(){
  return {
    type: MARK_SUCCESS,
  }
}

export function markFailure(errors = []){
  return {
    type: MARK_FAILURE,
    errors,
  }
}

const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadConversations(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/agency/conversations.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CONVERSATION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadEntityConversations(entityType, entityId, options){
  const entityKey = `${entityType}${entityId}`

  return (dispatch, getState) => {
    const { conversations } = getState()
    const loadedForEntities = conversations.loadedForEntities ? conversations.loadedForEntities.slice() : []
    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/conversations.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CONVERSATION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadConversation(conversationId, options, callback){
  return (dispatch, getState) => {
    const { conversations } = getState()
    const loadedIds = conversations.loadedIds ? conversations.loadedIds.slice() : []
    if (!loadedIds.includes(conversationId)){
      loadedIds.push(conversationId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/conversations/${conversationId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CONVERSATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadConversationForSubject(subjectType, subjectId, options, callback){
  const entityKey = `${subjectType}${subjectId}`

  return (dispatch, getState) => {
    const { conversations } = getState()
    const loadedForSubjects = conversations.loadedForSubjects ? conversations.loadedForSubjects.slice() : []
    if (!loadedForSubjects.includes(entityKey)){
      loadedForSubjects.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForSubjects }))

    const url = `/agency/conversations/${pascalToSnakeCase(subjectType)}/${subjectId}.json`

    const promise = api(url, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        if (json.id){
          const normalizedJson = normalize(json, Schemas.CONVERSATION)
          dispatch(updateEntities(normalizedJson))
        }

        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createSubjectConversation(conversation, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      conversation,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/conversations/create_subject_conversation.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CONVERSATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function markCommentsAsRead(conversation, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      conversation: { id: conversation.id },
    }),
  }

  return (dispatch) => {
    dispatch(markRequest())

    const promise = api(`/agency/conversations/${conversation.id}/mark_comments_as_read.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CONVERSATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(markSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(markFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
        loadedForSubjects: action.loadedForSubjects || state.loadedForSubjects,
        loadedIds: action.loadedIds || state.loadedIds,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case SELECT_CONVERSATION:
      return { ...state, selectedId: action.selectedId }
    case MARK_REQUEST:
      return { ...state, marking: true }
    case MARK_SUCCESS:
      return {
        ...state,
        marking: false,
        errors: [],
      }
    case MARK_FAILURE:
      return {
        ...state,
        marking: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
