import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const CREATE_REQUEST = 'realhub/campaignProposalSection/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignProposalSection/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignProposalSection/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/campaignProposalSection/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaignProposalSection/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaignProposalSection/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaignProposalSection/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignProposalSection/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignProposalSection/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  updating: false,
  deleting: false,
}

// Actions

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createCampaignProposalSection(campaignProposalSection, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_proposal_section: campaignProposalSection,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const { campaign_proposal_id } = campaignProposalSection
    const promise = api(`/agency/campaign_proposals/${campaign_proposal_id}/sections.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL_SECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updateCampaignProposalSection(campaignProposalSection, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign_proposal_section: campaignProposalSection,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const { campaign_proposal_id, id } = campaignProposalSection
    const promise = api(`/agency/campaign_proposals/${campaign_proposal_id}/sections/${id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL_SECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deleteCampaignProposalSection(campaignProposalSection, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const { campaign_proposal_id, id } = campaignProposalSection
    const promise = api(`/agency/campaign_proposals/${campaign_proposal_id}/sections/${id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const normalizedJson = normalize(json, Schemas.CAMPAIGN_PROPOSAL_SECTION)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case UPDATE_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      })
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        errors: [],
      })
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        errors: action.errors,
      })
    case DELETE_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      })
    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
        errors: [],
      })
    case DELETE_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
