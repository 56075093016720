import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

import { addImage, deleteImage } from './image'

const FETCH_REQUEST = 'realhub/agency/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/agency/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/agency/FETCH_FAILURE'
const UPDATE_REQUEST = 'realhub/agency/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/agency/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/agency/UPDATE_FAILURE'

const SELECT_AGENCY = 'realhub/agency/SELECT_AGENCY'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedIds: [],
  loading: false,
  result: [],
  selected: {},
  selectedId: null,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function selectAgency(agencyId){
  return (dispatch, getState) => {
    const state = getState()
    const { agencies } = state.entities

    const selectedAgency = agencies[agencyId]

    dispatch({
      type: SELECT_AGENCY,
      selectedId: agencyId,
      selected: selectedAgency,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadAgencies(options = {}, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/agencies.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadAgency(agency, options = {}, callback){
  const { id } = agency

  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = [...state.agencies.loadedIds]
    if (id && !loadedIds.includes(id)){
      loadedIds.push(id)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/agencies/${id}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateAgency(agency, options = {}, callback, errorCallback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      agency,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/agencies/${agency.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess(normalizedJson))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        if (errorCallback) errorCallback(errors)

        return { success: false, result: errors }
      })

    return promise
  }
}

export function addAgencyImage(image = {}){
  return (dispatch, getState) => {
    const state = getState()

    // Add the image
    dispatch(addImage(image))

    // Add the image ID to the agency
    const agency = { ...state.entities.agencies[image.imageable_id] }
    agency.images.push(image.id)

    const normalizedJson = normalize(agency, Schemas.AGENCY)
    dispatch(updateEntities(normalizedJson))
  }
}

export function deleteAgencyImage(image = {}){
  return (dispatch, getState) => {
    const state = getState()

    // Remove the image from the agency
    const agencyEntity = { ...state.entities.agencies[image.imageable_id] }
    agencyEntity.images.some((imageId, index) => {
      if (imageId === image.id){
        agencyEntity.images.splice(index, 1)
        return true
      }
    })

    // Delete the image
    dispatch(deleteImage(image))

    const normalizedJson = normalize(agencyEntity, Schemas.AGENCY)
    dispatch(updateEntities(normalizedJson))
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        result: action.result,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case SELECT_AGENCY:
      return { ...state, ...action }
    default:
      return state
  }
}
