import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/artworkComment/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/artworkComment/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/artworkComment/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/artworkComment/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/artworkComment/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/artworkComment/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/artworkComment/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/artworkComment/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/artworkComment/UPDATE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  loadedCommentsForArtworkIds: [],
  creating: false,
  updating: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedCommentsForArtworkIds: payload.loadedCommentsForArtworkIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(payload = {}){
  return {
    type: UPDATE_SUCCESS,
    result: payload.result,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadArtworkComments(options){
  return (dispatch, getState) => {
    const loadedCommentsForArtworkIds = getState().artworkComments.loadedCommentsForArtworkIds ?
      getState().artworkComments.loadedCommentsForArtworkIds.slice() : []

    if (options.artwork_id){
      loadedCommentsForArtworkIds.push(options.artwork_id)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedCommentsForArtworkIds }))

    const promise = api(`/agency/artworks/${options.artwork_id}/comments.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_COMMENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadCommentsForArtworkIds(artworkIds = [], options = {}){
  return (dispatch, getState) => {
    const loadedCommentsForArtworkIds = getState().artworkComments.loadedCommentsForArtworkIds ?
      getState().artworkComments.loadedCommentsForArtworkIds.slice() : []

    const mergedLoadedIds = loadedCommentsForArtworkIds.concat(artworkIds)

    // Set Loading
    dispatch(fetchRequest({ loadedCommentsForArtworkIds: mergedLoadedIds }))

    const requestOptions = Object.assign({}, options, { artwork_ids: JSON.stringify(artworkIds) })
    const promise = api('/agency/artwork_comments.json', requestOptions)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_COMMENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createArtworkComment(artworkComment, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      artwork_comment: artworkComment,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/artworks/${artworkComment.artwork_id}/comments.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().artworkComments.result ? getState().artworkComments.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index == -1){
          resultArray.push(json.id)
        }

        // Add comment to artwork
        const updatedArtwork = getState().entities.artworks[artworkComment.artwork_id] ?
          Object.assign({}, getState().entities.artworks[artworkComment.artwork_id]) : {}

        if (!updatedArtwork.id){
          return Promise.reject({ errors: ['Artwork not found'] })
        } else if (updatedArtwork.comments){
          const normalizedArtwork = normalize(json, Schemas.ARTWORK)
          dispatch(updateEntities(normalizedArtwork))
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_COMMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updateArtworkComment(artworkComment, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      v2: true,
      artwork_comment: artworkComment,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const url = `/agency/artworks/${artworkComment.artwork_id}/comments/${artworkComment.id}.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_COMMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        loadedCommentsForArtworkIds: action.loadedCommentsForArtworkIds,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        result: action.result,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case UPDATE_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      })
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        errors: [],
      })
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
