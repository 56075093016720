import { normalize } from 'normalizr'
import api from 'api'
import { Schemas } from '../../../shared/redux/schema'
import { updateEntities } from '../../../shared/redux/entity'

const FETCH_REQUEST = 'realhub/providerPackage/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/providerPackage/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/providerPackage/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedForEntities: [],
  loading: false,
  result: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loading: true,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadProviderPackages(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/agency/provider_packages.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PROVIDER_PACKAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = json.errors ? json.errors : ['Something went wrong']
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadPackagesForEntity(entityKey, options){
  return (dispatch, getState) => {
    // Set Loading
    const loadedForEntities = [...getState().providerPackages.loadedForEntities]

    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/provider_packages.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PROVIDER_PACKAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
