import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/tag/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/tag/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/tag/FETCH_FAILURE'

// Initial State
const initialState = {
  loaded: false,
  loadedIds: [],
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// HELPER FUNCTIONS
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadTags(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/tags.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.TAG_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        loadedIds: action.loadedIds || state.loadedIds,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
