import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/booking/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/booking/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/booking/FETCH_FAILURE'

const CREATE_REQUEST = 'realhub/booking/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/booking/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/booking/CREATE_FAILURE'

const UPDATE_REQUEST = 'realhub/booking/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/booking/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/booking/UPDATE_FAILURE'

const SELECT_BOOKING = 'realhub/booking/SELECT_BOOKING'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  selected: {},
  selectedId: null,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function selectBookingId(bookingId){
  return (dispatch, getState) => {
    const state = getState()
    const { bookings } = state.entities

    const selectedBooking = bookings[bookingId] || {}

    dispatch({
      type: SELECT_BOOKING,
      selectedId: bookingId,
      selected: selectedBooking,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function hydrateBooking(booking){
  const normalizedJson = normalize(booking, Schemas.BOOKING)

  return (dispatch) => {
    dispatch(updateEntities(normalizedJson))
  }
}

export function loadBookings(options){
  const { entityKey } = options

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().bookings.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/agency/bookings.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.BOOKING_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadBooking(booking, options){
  return (dispatch, getState) => {
    const loadedIds = [...getState().bookings.loadedIds]
    if (!loadedIds.includes(booking.id)){
      loadedIds.push(booking.id)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/bookings/${booking.id}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function searchBookings(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/agency/bookings/search.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.BOOKING_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createBooking(booking, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      booking,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/bookings.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function createMultipleBookings(booking, orders, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      booking,
      orders,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/bookings/create_multiple.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.BOOKING_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateBooking(booking, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      booking,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/bookings/${booking.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function cancelBooking(booking, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/bookings/${booking.id}/cancel.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const normalizedJson = normalize(json, Schemas.BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function sendNotification(booking, notificationType, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      notification_type: notificationType,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/bookings/${booking.id}/send_notification.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case SELECT_BOOKING:
      return {
        ...state,
        selectedId: action.selectedId,
        selected: action.selected,
      }
    default:
      return state
  }
}
