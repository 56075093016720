import { normalize } from 'normalizr'
import { Schemas } from '../../../shared/redux/schema'
import { updateEntities } from '../../../shared/redux/entity'
import { encodeParams } from '../../../shared/functions/utilities'

const FETCH_REQUEST = 'realhub/agencyProviderPriceOverride/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/agencyProviderPriceOverride/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/agencyProviderPriceOverride/FETCH_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
    loading: true,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}

// Action Creators
export function loadAgencyProviderPriceOverrides(options){
  const config = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    // Encode Params
    const params = options ? `?${encodeParams(options)}` : ''
    const promise = fetch(`/agency/provider_price_overrides.json${params}`, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY_PROVIDER_PRICE_OVERRIDE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = json.errors ? json.errors : ['Something went wrong']
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
