import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'
import api from 'api'

const CREATE_REQUEST = 'realhub/apiKey/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/apiKey/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/apiKey/CREATE_FAILURE'

const DELETE_REQUEST = 'realhub/apiKey/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/apiKey/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/apiKey/DELETE_FAILURE'

const UPDATE_REQUEST = 'realhub/apiKey/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/apiKey/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/apiKey/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  updating: false,
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createApiKey(apiKey, options = {}, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      api_key: apiKey,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/api_keys.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.API_KEY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function bulkCreateApiKeys(agency, title, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      agency_id: agency.id,
      title,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/api_keys/bulk_create_for_agency.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.API_KEY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateApiKey(apiKey, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      api_key: apiKey,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/api_keys/${apiKey.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.API_KEY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteApiKey(apiKey, options = {}){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/api_keys/${apiKey.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.API_KEY)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
