import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/publication/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/publication/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/publication/FETCH_FAILURE'

const SELECT_PUBLICATION = 'realhub/publication/SELECT_PUBLICATION'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  loaded: false,
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
    loading: true,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}

export function selectPublication(publication = {}){
  return (dispatch) => {
    dispatch({
      type: SELECT_PUBLICATION,
      selectedId: publication.id,
    })
  }
}

// Action Creators
export function loadPublications(options, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/publications.json', options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = json.errors || ['Something went wrong']
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case SELECT_PUBLICATION:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
