import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/brand/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/brand/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/brand/FETCH_FAILURE'

const SELECT_BRAND = 'realhub/brand/SELECT_BRAND'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedIds: [],
  loading: false,
  result: [],
  selectedId: null,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function selectBrandId(brandId){
  return (dispatch) => {
    dispatch({
      type: SELECT_BRAND,
      selectedId: brandId,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function hydrateBrand(brand){
  return (dispatch, getState) => {
    const loadedIds = [...getState().brands.loadedIds]
    if (!loadedIds.includes(brand.id)){
      loadedIds.push(brand.id)
    }
    dispatch(fetchRequest({ loadedIds }))

    if (brand.id){
      const normalizedJson = normalize(brand, Schemas.BRAND)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess())
    } else {
      dispatch(fetchFailure(['Could not hydrate undefined brand.']))
    }
  }
}

export function loadBrands(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/brands.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.BRAND_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case SELECT_BRAND:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
