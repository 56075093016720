import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/layoutTemplate/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/layoutTemplate/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/layoutTemplate/FETCH_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loadedForEntities: [],
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadLayoutTemplates(options, callback){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForEntities = [...getState().layoutTemplates.loadedForEntities]
    if (entityKey && !loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/layout_templates.json', options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.LAYOUT_TEMPLATE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
