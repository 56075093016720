import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/customFieldSet/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/customFieldSet/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/customFieldSet/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCustomFieldSets(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().customFieldSets.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/agency/custom_field_sets.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CUSTOM_FIELD_SET_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
