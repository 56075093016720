import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/artworkReview/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/artworkReview/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/artworkReview/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/artworkReview/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/artworkReview/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/artworkReview/CREATE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  loadedReviewsForArtworkIds: [],
  creating: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedReviewsForArtworkIds: payload.loadedReviewsForArtworkIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadArtworkReviews(options){
  return (dispatch, getState) => {
    const loadedReviewsForArtworkIds = getState().artworkReviews.loadedReviewsForArtworkIds
      ? getState().artworkReviews.loadedReviewsForArtworkIds.slice() : []

    if (options.artwork_id){
      loadedReviewsForArtworkIds.push(options.artwork_id)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedReviewsForArtworkIds }))

    const promise = api(`/agency/artworks/${options.artwork_id}/reviews.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_REVIEW_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadPendingReviewedOrders(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/artwork_reviews/pending_orders.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadReviewsForArtworkIds(artworkIds = [], options = {}){
  return (dispatch, getState) => {
    const loadedReviewsForArtworkIds = getState().artworkReviews.loadedReviewsForArtworkIds
      ? getState().artworkReviews.loadedReviewsForArtworkIds.slice() : []

    const mergedLoadedIds = loadedReviewsForArtworkIds.concat(artworkIds)

    // Set Loading
    dispatch(fetchRequest({ loadedReviewsForArtworkIds: mergedLoadedIds }))

    const requestOptions = { ...options, artwork_ids: JSON.stringify(artworkIds) }
    const promise = api('/agency/artwork_reviews.json', requestOptions)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_REVIEW_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createArtworkReview(artworkReview, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      artwork_review: artworkReview,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    // Encode Params
    const promise = api(`/agency/artworks/${artworkReview.artwork_id}/reviews.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().artworkReviews.result ? getState().artworkReviews.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        // Add review to artwork
        const updatedArtwork = getState().entities.artworks[artworkReview.artwork_id]
          ? ({ ...getState().entities.artworks[artworkReview.artwork_id] }) : {}

        if (!updatedArtwork.id){
          return Promise.reject({ errors: ['Artwork not found'] })
        } if (updatedArtwork.reviews){
          updatedArtwork.reviews.push(json)
          const normalizedArtwork = normalize(json, Schemas.ARTWORK)
          dispatch(updateEntities(normalizedArtwork))
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_REVIEW)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedReviewsForArtworkIds: action.loadedReviewsForArtworkIds || state.loadedReviewsForArtworkIds,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result || state.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result || state.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
