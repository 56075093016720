import { normalize } from 'normalizr'
import { Schemas } from '../../../shared/redux/schema'
import { updateEntities, deleteEntity } from '../../../shared/redux/entity'
import { encodeParams } from '../../../shared/functions/utilities'

const CREATE_REQUEST = 'realhub/campaignQuoteTemplateItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignQuoteTemplateItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignQuoteTemplateItem/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/campaignQuoteTemplateItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaignQuoteTemplateItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaignQuoteTemplateItem/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaignQuoteTemplateItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignQuoteTemplateItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignQuoteTemplateItem/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  creating: false,
  updating: false,
  deleting: false,
  errors: []
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
    creating: true
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    creating: false,
    result: payload.result,
    errors: []
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    creating: false,
    errors: errors
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
    updating: true
  }
}

export function updateSuccess(payload = {}){
  return {
    type: UPDATE_SUCCESS,
    updating: false,
    errors: []
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    updating: false,
    errors: errors
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
    deleting: true
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    deleting: false,
    result: payload.result,
    errors: []
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    deleting: false,
    errors: errors
  }
}

// Action Creators
export function createCampaignQuoteTemplateItem(campaignQuoteTemplateItem){
  let config = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ campaign_quote_template_item: campaignQuoteTemplateItem, v2: true })
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest());

    let promise = fetch(`/agency/campaign_quote_templates/${campaignQuoteTemplateItem.campaign_quote_template_id}/items.json`, config)
      .then(response => {
        return response.json().then( json => ({ json, response }) )
      })
      .then(({ json, response }) => {
        if(!response.ok){
          return Promise.reject(json);
        }
        
        // Add to result array
        let resultArray = getState().campaignQuoteTemplateItems.result ? getState().campaignQuoteTemplateItems.result.slice() : [];
        resultArray.push(json.id);

        // Add to CampaignQuoteTemplate
        let quoteTemplate = Object.assign({}, getState().entities.campaignQuoteTemplates[json.campaign_quote_template_id]);
        quoteTemplate.items.push(json.id);

        dispatch(updateEntities(normalize(quoteTemplate, Schemas.CAMPAIGN_QUOTE_TEMPLATE)));
        dispatch(updateEntities(normalize(json, Schemas.CAMPAIGN_QUOTE_TEMPLATE_ITEM)));
        dispatch(createSuccess({ result: resultArray }));
      })
      .catch(json => {
        console.log(json);
        dispatch(createFailure(json.errors));
      })

    return promise
  }
}

export function updateCampaignQuoteTemplateItem(campaignQuoteTemplateItem){
  let config = {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ campaign_quote_template_item: campaignQuoteTemplateItem, v2: true })
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(updateRequest());

    let promise = fetch(`/agency/campaign_quote_templates/${campaignQuoteTemplateItem.campaign_quote_template_id}/items/${campaignQuoteTemplateItem.id}.json`, config)
      .then(response => {
        return response.json().then( json => ({ json, response }) )
      })
      .then(({ json, response }) => {
        if(!response.ok){
          return Promise.reject(json);
        }

        dispatch(updateEntities(normalize(json, Schemas.CAMPAIGN_QUOTE_TEMPLATE_ITEM)));
        dispatch(updateSuccess());
      })
      .catch(json => {
        console.log(json);
        dispatch(updateFailure(json.errors));
      })

    return promise
  }
}

export function deleteCampaignQuoteTemplateItem(campaignQuoteTemplateItem){
  let config = {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ v2: true })
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest());

    let promise = fetch(`/agency/campaign_quote_templates/${campaignQuoteTemplateItem.campaign_quote_template_id}/items/${campaignQuoteTemplateItem.id}.json`, config)
      .then(response => {
        return response.json().then( json => ({ json, response }) )
      })
      .then(({ json, response }) => {
        if(!response.ok){
          return Promise.reject(json);
        }
        
        // Remove from result array
        let resultArray = getState().campaignQuoteTemplateItems.result ? getState().campaignQuoteTemplateItems.result.slice() : [];
        let index = resultArray.indexOf(campaignQuoteTemplateItem.id);
        resultArray.splice(index, 1);

        // Remove from CampaignQuoteTemplate
        let quoteTemplate = Object.assign({}, getState().entities.campaignQuoteTemplates[campaignQuoteTemplateItem.campaign_quote_template_id]);
        let quoteIndex = quoteTemplate.items.indexOf(campaignQuoteTemplateItem.id);
        quoteTemplate.items.splice(quoteIndex, 1);

        dispatch(updateEntities(normalize(quoteTemplate, Schemas.CAMPAIGN_QUOTE_TEMPLATE)));
        dispatch(deleteEntity(normalize(campaignQuoteTemplateItem, Schemas.CAMPAIGN_QUOTE_TEMPLATE_ITEM)));
        dispatch(deleteSuccess({ result: resultArray }));
      })
      .catch(json => {
        console.log(json);
        dispatch(deleteFailure(json.errors));
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch(action.type){
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true
      });
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        result: action.result,
        errors: []
      });
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors
      });
    case UPDATE_REQUEST:
      return Object.assign({}, state, {
        updating: true
      });
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        errors: []
      });
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        errors: action.errors
      });
    case DELETE_REQUEST:
      return Object.assign({}, state, {
        deleting: true
      });
    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
        result: action.result
      });
    case DELETE_FAILURE:
      return Object.assign({}, state, {
        deleting: true,
        errors: action.errors
      });
    default:
      return state;
  }
}