import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'
import api from 'api'

const FETCH_REQUEST = 'realhub/providerPriceList/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/providerPriceList/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/providerPriceList/FETCH_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loadedPriceListKeys: [],
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loading: true,
    loadedPriceListKeys: payload.loadedPriceListKeys,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadProviderPriceLists(priceListKey, options){
  return (dispatch, getState) => {
    const priceLists = getState().providerPriceLists

    // Set Loading and add key to loaded array
    const loadedPriceListKeys = priceLists.loadedPriceListKeys ? priceLists.loadedPriceListKeys.slice() : []
    if (!loadedPriceListKeys.includes(priceListKey)){
      loadedPriceListKeys.push(priceListKey)
    }
    dispatch(fetchRequest({ loadedPriceListKeys }))

    const promise = api('/agency/provider_price_lists.json', options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PROVIDER_PRICE_LIST_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        loadedPriceListKeys: action.loadedPriceListKeys,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
