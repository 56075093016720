import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const UPDATE_REQUEST = 'realhub/geoSuburb/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/geoSuburb/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/geoSuburb/UPDATE_FAILURE'

// Initial State
const initialState = {
  updating: false,
  errors: [],
}

// Actions
export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function refreshSuburbData(suburb, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/admin/geo_suburbs/${suburb.id}/refresh_data.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.GEO_SUBURB)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
