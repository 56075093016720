const CHANGE_SETTING = 'realhub/agency/artworkBuilder/editPage/CHANGE_SETTING'
const CHANGE_MULTIPLE_SETTINGS = 'realhub/agency/artworkBuilder/editPage/CHANGE_MULTIPLE_SETTINGS'

// Initial State
const initialState = {
  appliedDataPatches: [],
  rightSidebarTab: 'dataChanges', // Default to dataChanges so that query will run on mount
  showRightSidebar: false,
}

// Actions
export function changeSetting(name, value){
  return {
    type: CHANGE_SETTING,
    name,
    value,
  }
}

export function changeMultipleSettings(filters = {}){
  return {
    type: CHANGE_MULTIPLE_SETTINGS,
    filters,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_SETTING:
      return {
        ...state,
        ...{
          [action.name]: action.value,
        },
      }
    case CHANGE_MULTIPLE_SETTINGS:
      return {
        ...state,
        ...action.filters,
      }
    default:
      return state
  }
}
