import api from 'api'

const FETCH_REQUEST = 'realhub/currentUser/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/currentUser/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/currentUser/FETCH_FAILURE'

const UPDATE_REQUEST = 'realhub/currentUser/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/currentUser/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/currentUser/UPDATE_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
    errors: [],
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function hydrateCurrentUser(user){
  return (dispatch) => {
    dispatch(fetchRequest())

    if (user && user.id){
      dispatch(fetchSuccess(user))
    } else {
      dispatch(fetchFailure(['Could not hydrate undefined user.']))
    }
  }
}

export function loadCurrentUser(passedOptions = {}){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const options = {
      include_image_sizes: true,
      include_user_association: true,
      include_user_image: true,
      include_user_images: true,
      include_user_role: true,
      ...passedOptions,
    }

    // Encode Params
    const promise = api('/admin/users/me.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        dispatch(fetchSuccess(json))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCurrentUser(user, passedOptions = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      user,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const options = {
      include_user_association: true,
      include_user_images: true,
      include_user_role: true,
      ...passedOptions,
    }

    // Encode Params
    const promise = api(`/agency/users/${user.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        dispatch(updateSuccess(json))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, payload, errors } = action

  switch (type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
