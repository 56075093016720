import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/campaign/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaign/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaign/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/campaign/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaign/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaign/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/campaign/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaign/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaign/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaign/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaign/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaign/DELETE_FAILURE'

const SELECT_CAMPAIGN = 'realhub/campaign/SELECT_CAMPAIGN'

const SHARE_REQUEST = 'realhub/campaign/SHARE_REQUEST'
const SHARE_SUCCESS = 'realhub/campaign/SHARE_SUCCESS'
const SHARE_FAILURE = 'realhub/campaign/SHARE_FAILURE'

const SYNC_REQUEST = 'realhub/campaign/SYNC_REQUEST'
const SYNC_SUCCESS = 'realhub/campaign/SYNC_SUCCESS'
const SYNC_FAILURE = 'realhub/campaign/SYNC_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  selectedId: null,
  sharing: false,
  syncing: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
    errors: [],
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function syncRequest(){
  return {
    type: SYNC_REQUEST,
  }
}

export function syncSuccess(){
  return {
    type: SYNC_SUCCESS,
    errors: [],
  }
}

export function syncFailure(errors = []){
  return {
    type: SYNC_FAILURE,
    errors,
  }
}

export function shareRequest(){
  return {
    type: SHARE_REQUEST,
  }
}

export function shareSuccess(){
  return {
    type: SHARE_SUCCESS,
  }
}

export function shareFailure(errors = []){
  return {
    type: SHARE_FAILURE,
    errors,
  }
}

export function selectCampaign(campaign){
  return (dispatch, getState) => {
    const state = getState()
    const { campaigns } = state.entities

    const selectedCampaign = campaigns[campaign.id]

    dispatch({
      type: SELECT_CAMPAIGN,
      selectedId: campaign.id,
      selected: selectedCampaign,
    })
  }
}

export function hydrateCampaign(campaign){
  return (dispatch, getState) => {
    const loadedIds = getState().campaigns.loadedIds ? getState().campaigns.loadedIds.slice() : []
    if (!loadedIds.includes(campaign.id)){
      loadedIds.push(campaign.id)
    }
    dispatch(fetchRequest({ loadedIds }))

    if (campaign.id){
      const normalizedJson = normalize(campaign, Schemas.CAMPAIGN)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess())
    } else {
      dispatch(fetchFailure(['Could not hydrate undefined campaign.']))
    }
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCampaigns(options){
  const { queryKey } = options

  return (dispatch, getState) => {
    const state = getState()
    const loadedForKeys = [...state.campaigns.loadedForKeys]
    if (queryKey && !loadedForKeys.includes(queryKey)){
      loadedForKeys.push(queryKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/agency/campaigns.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadCampaign(campaignId, options, callback){
  return (dispatch, getState) => {
    // Set Loading
    const loadedIds = [...getState().campaigns.loadedIds]
    if (!loadedIds.includes(campaignId)){
      loadedIds.push(campaignId)
    }
    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/campaigns/${campaignId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadCampaignReconciliationTotals(campaign, options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/reconciliation_totals.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function searchCampaigns(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/campaigns/search.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createCampaign(campaign, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      campaign,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/campaigns.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createActivity(campaign, activity, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      key: activity.key,
      parameters: JSON.stringify(activity.parameters),
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/create_activity.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ACTIVITY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function createPublicToken(campaign, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/create_public_token.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCampaign(campaign, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      v2: true,
      campaign,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaigns/${campaign.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteCampaign(campaign, options){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/campaigns/${campaign.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function checkDuplicateCampaign(campaign, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/campaigns/check_duplicate.json', {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        if (callback){
          callback(json)
        }
      })

    return promise
  }
}

export function checkRequiredFields(campaign){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/portal_validation.json`)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function checkImageExportSizes(campaign){
  return () => {
    const promise = api(`/agency/campaigns/${campaign.id}/check_image_export_sizes.json`)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        return { success: false, result: errors }
      })

    return promise
  }
}

export function portalSync(campaign, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(syncRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/portal_sync.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(syncSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(syncFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function publishCampaign(campaign, options = {}){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(syncRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/publish.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(syncSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(syncFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function unpublishCampaign(campaign, options = {}){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(syncRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/unpublish.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(syncSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(syncFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function emailDashboardLink(campaign, linkAnchor, subject, message, recipients, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      link_anchor: linkAnchor,
      message,
      recipients,
      subject,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(shareRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/email_dashboard_link.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(shareSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(shareFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function shareMobileDashboardLink(campaign, linkAnchor, message, recipients, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      link_anchor: linkAnchor,
      message,
      recipients,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(shareRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/share_mobile_link.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(shareSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(shareFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function sendUploadNotification(campaign, providerId, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      provider_id: providerId,
    }),
  }

  return (dispatch) => {
    dispatch(shareRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/upload_notification.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(shareSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(shareFailure(errors))
      })

    return promise
  }
}

export function campaignApiImport(externalId, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      external_id: externalId,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/campaigns/api_import.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function imageDownload(campaign, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/image_download.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function artworkBuilderConfig(campaign, options){
  return () => {
    const promise = api(`/agency/campaigns/${campaign.id}/artwork_builder_config.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        return { success: false, result: errors }
      })

    return promise
  }
}

export function mergeCampaigns(sourceCampaign, destinationCampaign, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      destination_campaign_id: destinationCampaign.id,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaigns/${sourceCampaign.id}/merge_with_campaign.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function bulkShareArtwork(campaign, payload, options = {}){
  const {
    artwork_ids, message, subject, user_ids,
  } = payload || {}

  const config = {
    method: 'POST',
    body: JSON.stringify({
      artwork_ids: artwork_ids || [],
      message,
      subject,
      user_ids: user_ids || [],
    }),
  }

  return () => {
    const promise = api(`/agency/campaigns/${campaign.id}/bulk_share_artwork.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case SELECT_CAMPAIGN:
      return { ...state, selectedId: action.selectedId }
    case SHARE_REQUEST:
      return { ...state, sharing: true }
    case SHARE_SUCCESS:
      return {
        ...state,
        sharing: false,
        errors: [],
      }
    case SHARE_FAILURE:
      return {
        ...state,
        sharing: false,
        errors: action.errors,
      }
    case SYNC_REQUEST:
      return { ...state, syncing: true }
    case SYNC_SUCCESS:
      return {
        ...state,
        syncing: false,
        errors: [],
      }
    case SYNC_FAILURE:
      return {
        ...state,
        syncing: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
