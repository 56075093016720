import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity, replaceEntity } from 'redux-modules/entity'
import moment from 'moment'

import api from 'api'

const FETCH_REQUEST = 'realhub/artworkPageItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/artworkPageItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/artworkPageItem/FETCH_FAILURE'
const INITIAL_RENDER = 'realhub/artworkPageItem/INITIAL_RENDER'
const REBUILD_RENDERS = 'realhub/artworkPageItem/REBUILD_RENDERS'
const UPDATE_QUEUE = 'realhub/artworkPageItem/UPDATE_QUEUE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  initialRender: true,
  loaded: false,
  loadedItemsForArtworkPageIds: [],
  loading: false,
  processedRenders: {},
  queuedItems: [],
  rendering: false,
  result: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedItemsForArtworkPageIds: payload.loadedItemsForArtworkPageIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loadedItemsForArtworkPageIds: payload.loadedItemsForArtworkPageIds,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function initialRender(){
  return {
    type: INITIAL_RENDER,
  }
}

export function rebuildRenders(payload = {}){
  return {
    type: REBUILD_RENDERS,
    result: payload.result,
  }
}

export function updateQueue(payload = {}){
  return {
    type: UPDATE_QUEUE,
    processedRenders: payload.processedRenders,
    queuedItems: payload.queuedItems,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadItemsForArtworkPage(artworkPageId){
  return (dispatch, getState) => {
    const loadedItemsForArtworkPageIds = getState().artworkPageItems.loadedItemsForArtworkPageIds
      ? getState().artworkPageItems.loadedItemsForArtworkPageIds.slice()
      : []

    if (artworkPageId && !loadedItemsForArtworkPageIds.includes(artworkPageId)){
      loadedItemsForArtworkPageIds.push(artworkPageId)
    }
    dispatch(fetchRequest({ loadedItemsForArtworkPageIds }))
  }
}

export function loadArtworkPageItems(options){
  return (dispatch, getState) => {
    // Set Loading
    const loadedItemsForArtworkPageIds = getState().artworkPageItems.loadedItemsForArtworkPageIds
      ? getState().artworkPageItems.loadedItemsForArtworkPageIds.slice()
      : []

    if (options.artwork_page_id && !loadedItemsForArtworkPageIds.includes(options.artwork_page_id)){
      loadedItemsForArtworkPageIds.push(options.artwork_page_id)
    }
    dispatch(fetchRequest({ loadedItemsForArtworkPageIds }))

    const promise = api(`/agency/artworks/${options.artwork_id}/pages/${options.artwork_page_id}/items.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function deleteArtworkPageItemRenders(artworkPage){
  return (dispatch, getState) => {
    const resultArray = getState().artworkPageItems.result ? getState().artworkPageItems.result.slice() : []

    const { artworkPageItems } = getState().entities
    const keys = Object.keys(artworkPageItems)

    keys.forEach((key) => {
      const artworkPageItem = artworkPageItems[key]
      if (artworkPageItem.artwork_page_id === artworkPage.id){
        const deletedItem = { ...artworkPageItem }

        const index = resultArray.indexOf(deletedItem.id)
        if (index !== -1){
          resultArray.splice(index, 1)
        }

        // Update
        const normalizedJson = normalize(deletedItem, Schemas.ARTWORK_PAGE_ITEM)
        dispatch(deleteEntity(normalizedJson))
      }
    })

    dispatch(rebuildRenders({ result: resultArray }))
  }
}

export function deleteRendersForArtworkDataKeys(artworkPage, artworkDataKeys = []){
  return (dispatch, getState) => {
    const { artworkPageItems } = getState().entities
    const keys = Object.keys(artworkPageItems)

    keys.forEach((key) => {
      const artworkPageItem = artworkPageItems[key]
      if (
        artworkPageItem.artwork_page_id === artworkPage.id
        && artworkDataKeys.includes(artworkPageItem.artwork_data_key)
      ){
        const updatedArtworkPageItem = {
          ...artworkPageItem,
          render_url: null,
        }

        const normalizedJson = normalize(updatedArtworkPageItem, Schemas.ARTWORK_PAGE_ITEM)
        dispatch(updateEntities(normalizedJson))
      }
    })
  }
}

export function clearArtworkPageItemRender(artworkPage, templatePageItem){
  return (dispatch, getState) => {
    const { artworkPageItems } = getState().entities

    Object.values(artworkPageItems).forEach((artworkPageItem) => {
      if (
        artworkPageItem.artwork_page_id === artworkPage.id
        && artworkPageItem.artwork_data_key === templatePageItem.artwork_data_key
      ){
        const updatedArtworkPageItem = {
          ...artworkPageItem,
          render_url: null,
        }

        const normalizedJson = normalize(updatedArtworkPageItem, Schemas.ARTWORK_PAGE_ITEM)
        dispatch(updateEntities(normalizedJson))
      }
    })
  }
}

function getItemKey(templatePageItem){
  return templatePageItem.artwork_data_key
}

function updateProcessedRenders(state, templatePageItem){
  const itemKey = getItemKey(templatePageItem)

  const processedRenders = { ...state.artworkPageItems.processedRenders }

  const previousRenderTime = processedRenders[itemKey] ? processedRenders[itemKey].renderTime.valueOf() : 0
  const currentTime = moment().valueOf()

  processedRenders[itemKey] = {
    artworkDataKey: itemKey,
    templatePageItemId: templatePageItem.id,
    renderTime: currentTime,
    previousRenderTime,
    previousRenderDiff: currentTime - previousRenderTime,
  }

  return processedRenders
}

function modifyItemQueue(state, templatePageItem, action = 'add'){
  const itemKey = getItemKey(templatePageItem)

  const queuedItems = state.artworkPageItems.queuedItems ? state.artworkPageItems.queuedItems.slice() : []
  const queueIndex = queuedItems.indexOf(itemKey)

  // Add to Queue
  if (action === 'add' && queueIndex === -1){
    queuedItems.push(itemKey)
  }

  // Remove from Queue
  if (action === 'remove' && queueIndex !== -1){
    queuedItems.splice(queueIndex, 1)
  }

  return queuedItems
}

export function renderArtworkPageItem(artworkPage, templatePageItem, options){
  const {
    id, artwork_data_key, parent, scale, scaleDimensions, height, width, x, y,
  } = templatePageItem || {}

  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      template_page_item_id: id,
      artwork_data_key,
      parent_type: parent ? parent.item_type : null,
      parent_id: parent ? parent.id : null,
      parent_scale: scale || null,
      position: {
        height: scaleDimensions ? height : null,
        width: scaleDimensions ? width : null,
        x,
        y,
      },
    }),
  }

  return (dispatch, getState) => {
    const itemKey = getItemKey(templatePageItem)

    // Check how long ago this item was rendered
    const processedRenders = updateProcessedRenders(getState(), templatePageItem)

    // Add to Queue
    const queuedItems = modifyItemQueue(getState(), templatePageItem, 'add')
    dispatch(updateQueue({ queuedItems, processedRenders }))

    // If the render diff is less than 1000 milliseconds it wasn't performed by the user
    // So return and don't continue
    const itemRender = processedRenders[itemKey]
    if (itemRender && itemRender.previousRenderDiff < 1000) return

    // Set Loading
    const loadedItemsForArtworkPageIds = getState().artworkPageItems.loadedItemsForArtworkPageIds
      ? getState().artworkPageItems.loadedItemsForArtworkPageIds.slice()
      : []

    if (!loadedItemsForArtworkPageIds.includes(artworkPage.id)){
      loadedItemsForArtworkPageIds.push(artworkPage.id)
    }

    dispatch(fetchRequest({ loadedItemsForArtworkPageIds }))

    // Encode Params
    const url = `/agency/artworks/${artworkPage.artwork_id}/pages/${artworkPage.id}/items/render_item.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to Result Array
        const resultArray = getState().artworkPageItems.result ? getState().artworkPageItems.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        // Update Entity
        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE_ITEM)
        dispatch(replaceEntity(normalizedJson))
        dispatch(fetchSuccess({ result: resultArray }))

        // Remove from Queue
        const updatedQueuedItems = modifyItemQueue(getState(), templatePageItem, 'remove')
        dispatch(updateQueue({ queuedItems: updatedQueuedItems }))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function renderArtworkPageItems(artworkPage, templatePageItems, options){
  return (dispatch) => {
    templatePageItems.forEach((templatePageItem) => {
      dispatch(renderArtworkPageItem(artworkPage, templatePageItem, options))
    })

    dispatch(initialRender())
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedItemsForArtworkPageIds: action.loadedItemsForArtworkPageIds,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case INITIAL_RENDER:
      return { ...state, initialRender: false }
    case REBUILD_RENDERS:
      return {
        ...state,
        initialRender: true,
        rendering: true,
        result: action.result,
      }
    case UPDATE_QUEUE:
      return {
        ...state,
        processedRenders: action.processedRenders || state.processedRenders,
        queuedItems: action.queuedItems,
      }
    default:
      return state
  }
}
