import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/mediaLink/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/mediaLink/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/mediaLink/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/mediaLink/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/mediaLink/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/mediaLink/CREATE_FAILURE'
const DELETE_REQUEST = 'realhub/mediaLink/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/mediaLink/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/mediaLink/DELETE_FAILURE'

// Initial State
const initialState = {
  loaded: false,
  loadedForEntities: [],
  loading: false,
  creating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadEntityMediaLinks(entityType, entityId, options, callback){
  let url
  switch (entityType){
    default:
      url = `/agency/campaigns/${entityId}/media_links.json`
      break
  }

  const entityKey = `${entityType}${entityId}`

  return (dispatch, getState) => {
    const { mediaLinks } = getState()
    const loadedForEntities = [...mediaLinks.loadedForEntities]
    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api(url, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MEDIA_LINK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadMediaLinks(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/media_links.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MEDIA_LINK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createMediaLink(mediaLink, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      media_link: mediaLink,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/media_links.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MEDIA_LINK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateMediaLink(mediaLink, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      media_link: mediaLink,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/media_links/${mediaLink.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MEDIA_LINK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function deleteMediaLink(mediaLink, options, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/media_links/${mediaLink.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MEDIA_LINK)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateOrCreateMediaLink(mediaLink, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      media_link: mediaLink,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/media_links/update_or_create.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MEDIA_LINK)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
