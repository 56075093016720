import api from 'api'
import { normalize } from 'normalizr'
import { Schemas } from '../../../shared/redux/schema'
import { updateEntities, deleteEntity } from '../../../shared/redux/entity'

const CREATE_REQUEST = 'realhub/agencyContact/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/agencyContact/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/agencyContact/CREATE_FAILURE'

const UPDATE_REQUEST = 'realhub/agencyContact/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/agencyContact/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/agencyContact/UPDATE_FAILURE'

const DELETE_REQUEST = 'realhub/agencyContact/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/agencyContact/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/agencyContact/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  creating: false,
  deleting: false,
  updating: false,
  errors: [],
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
    creating: true,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    creating: false,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    creating: false,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
    deleting: true,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    deleting: false,
    errors: [],
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    deleting: false,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createAgencyContact(agencyContact, options, callback){
  const config = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ agency_contact: agencyContact, v2: true }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = fetch('/agency/agency_contacts.json', config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const state = getState()

        // Add the contact to the result array
        const contactArray = state.agencyContacts.result ? state.agencyContacts.result.slice() : []
        contactArray.push(json.id)
        dispatch(createSuccess({ result: contactArray }))

        // Add the entity
        dispatch(updateEntities(normalize(json, Schemas.AGENCY_CONTACT)))

        // Add the contact to the agency
        const agencyEntity = { ...state.entities.agencies[json.agency_id] }
        agencyEntity.contacts.push(json.id)

        dispatch(updateEntities(normalize(agencyEntity, Schemas.AGENCY)))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}
export function updateAgencyContact(agencyContact, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      agency_contact: agencyContact,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/agency_contacts/${agencyContact.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY_CONTACT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteAgencyContact(agencyContact){
  const config = {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ v2: true }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = fetch(`/agency/agency_contacts/${agencyContact.id}.json`, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const state = getState()

        // Remove the contact to the result array
        const contactArray = state.agencyContacts.result ? state.agencyContacts.result.slice() : []
        const index = contactArray.indexOf(agencyContact.id)
        contactArray.splice(index, 1)

        dispatch(deleteSuccess({ result: contactArray }))

        // Remove the entity
        dispatch(deleteEntity(normalize(agencyContact, Schemas.AGENCY_CONTACT)))

        // Remove the contact from the agency
        const agencyEntity = { ...state.entities.agencies[agencyContact.agency_id] }
        const contactIndex = agencyEntity.contacts.indexOf(agencyContact.id)
        agencyEntity.contacts.splice(contactIndex, 1)
        dispatch(updateEntities(normalize(agencyEntity, Schemas.AGENCY)))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
