import api from 'api'

const CHANGE_FILTER = 'realhub/proposalManager/dashboard/campaignProposals/CHANGE_FILTER'
const CHANGE_MULTIPLE_FILTERS = 'realhub/proposalManager/dashboard/campaignProposals/CHANGE_MULTIPLE_FILTERS'

const FETCH_REQUEST = 'realhub/proposalManager/dashboard/campaignProposals/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/proposalManager/dashboard/campaignProposals/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/proposalManager/dashboard/campaignProposals/FETCH_FAILURE'

// Initial State
const initialState = {
  filterStatus: 'all',
  filterString: '',
  insights: {},
  leaderboards: {},
  loading: false,
  processedRequests: [],
  errors: [],
}

// Actions
export function changeFilter(name, value){
  return {
    type: CHANGE_FILTER,
    name,
    value,
  }
}

export function changeMultipleFilters(filters = {}){
  return {
    type: CHANGE_MULTIPLE_FILTERS,
    filters,
  }
}

export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loading: true,
    queryKey: payload.queryKey,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    errors: [],
    stateKey: payload.stateKey,
    queryKey: payload.queryKey,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loading: false,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadInsightsForKey(queryKey, options = {}, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest({ queryKey }))

    const promise = api('/agency/campaign_proposals/insights.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess({ stateKey: 'insights', queryKey, result: json.result }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadLeaderboardForKey(queryKey, options = {}, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest({ queryKey }))

    const promise = api(`/agency/campaign_proposals/leaderboard.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess({ stateKey: 'leaderboards', queryKey, result: json.result }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_FILTER:
      return {
        ...state,
        [action.name]: action.value,
      }
    case CHANGE_MULTIPLE_FILTERS:
      return {
        ...state,
        ...action.filters,
      }
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        processedRequests: [...state.processedRequests, ...[action.queryKey]],
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.stateKey]: {
          ...state[action.stateKey],
          [action.queryKey]: action.result,
        },
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
