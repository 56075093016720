import * as activities from './activity'
import * as adCampaigns from './adCampaign'
import * as adCopies from './adCopy'
import * as addresses from './address'
import * as adminDashboard from '../../../admin/redux/modules/adminDashboard'
import * as agencies from './agency'
import * as agencyContacts from './agencyContact'
import * as agencyDashboard from './agencyDashboard'
import * as agencyManager from './agencyManager'
import * as agencyMarketing from './agencyMarketing'
import * as agencyPaymentMethods from './agencyPaymentMethod'
import * as agencyPortals from './agencyPortal'
import * as agencyProviderPriceOverrides from './agencyProviderPriceOverride'
import * as agencyTags from './agencyTag'
import * as analytics from './analytics'
import * as apiKeys from './apiKey'
import * as artworkBuilder from './artworkBuilder'
import * as artworkComments from './artworkComment'
import * as artworkPageItems from './artworkPageItem'
import * as artworkPages from './artworkPage'
import * as artworkReviews from './artworkReview'
import * as artworks from './artwork'
import * as assetLibraries from './assetLibrary'
import * as attachments from './attachment'
import * as bookings from './booking'
import * as brandGroupPublications from './brandGroupPublication'
import * as brands from './brand'
import * as campaignCategories from '../../../admin/redux/modules/campaignCategory'
import * as campaignCommercialCategories from '../../../admin/redux/modules/campaignCommercialCategory'
import * as campaignComparables from './campaignComparable'
import * as campaignContacts from './campaignContact'
import * as campaignContributions from './campaignContribution'
import * as campaignDocuments from './campaignDocument'
import * as campaignInspections from './campaignInspection'
import * as campaignItems from './campaignItem'
import * as campaignNotes from './campaignNote'
import * as campaignPortalSyncs from './campaignPortalSync'
import * as campaignPortals from './campaignPortal'
import * as campaignProposalSections from './campaignProposalSection'
import * as campaignProposals from './campaignProposal'
import * as campaignQuoteItems from './campaignQuoteItem'
import * as campaignQuoteSections from './campaignQuoteSection'
import * as campaignQuoteTemplateItems from './campaignQuoteTemplateItem'
import * as campaignQuoteTemplates from './campaignQuoteTemplate'
import * as campaignQuotes from './campaignQuote'
import * as campaignRuralCategories from '../../../admin/redux/modules/campaignRuralCategory'
import * as campaignSaleMethods from '../../../admin/redux/modules/campaignSaleMethod'
import * as campaignStatuses from '../../../admin/redux/modules/campaignStatus'
import * as campaignTypes from '../../../admin/redux/modules/campaignType'
import * as campaigns from './campaign'
import * as caseStudies from './caseStudy'
import * as comments from './comment'
import * as contacts from './contact'
import * as conversations from './conversation'
import * as creditNoteItems from './creditNoteItem'
import * as creditNotes from './creditNote'
import * as currentUser from './currentUser'
import * as customDeadlines from './customDeadline'
import * as customFieldSets from './customFieldSet'
import * as customItemGroups from './customItemGroup'
import * as customItems from './customItem'
import * as customShortcodes from '../../../admin/redux/modules/customShortcode'
import * as dataStoreItems from './dataStoreItem'
import * as defaultQuoteItems from './defaultQuoteItem'
import * as digitalComponents from './digitalComponent'
import * as digitalPageManager from './digitalPageManager'
import * as digitalPages from './digitalPage'
import * as digitalTemplates from './digitalTemplate'
import * as documentGroups from './documentGroup'
import * as documentSigners from './documentSigner'
import * as entities from '../../../shared/redux/entity'
import * as events from './event'
import * as favouriteOrders from './favouriteOrder'
import * as financeApplications from './financeApplication'
import * as geoCountries from './geoCountry'
import * as geoSuburbs from './geoSuburb'
import * as imageLibraries from './imageLibrary'
import * as images from './image'
import * as integrationData from './integrationData'
import * as integrations from './integration'
import * as interactiveImageTags from './interactiveImageTag'
import * as interactiveImages from './interactiveImage'
import * as invoiceItems from './invoiceItem'
import * as invoices from './invoice'
import * as layoutTemplates from './layoutTemplate'
import * as mediaLinks from './mediaLink'
import * as micrositeTemplates from '../../../admin/redux/modules/micrositeTemplate'
import * as microsites from './microsite'
import * as mobileDashboard from './mobileDashboard'
import * as notifications from './notification'
import * as orderBundles from './orderBundle'
import * as orderItemTaskTypes from '../../../admin/redux/modules/orderItemTaskType'
import * as orderItemTasks from './orderItemTask'
import * as orderItems from './orderItem'
import * as orders from './order'
import * as paymentMethods from '../../../admin/redux/modules/paymentMethod'
import * as portals from './portal'
import * as proposalManager from './proposalManager'
import * as providerAccounts from './providerAccount'
import * as providerPackages from './providerPackage'
import * as providerPriceListItems from './providerPriceListItem'
import * as providerPriceListProductUnlocks from './providerPriceListProductUnlock'
import * as providerPriceLists from './providerPriceList'
import * as providers from './provider'
import * as publicationBookingItems from './publicationBookingItem'
import * as publicationBookings from './publicationBooking'
import * as publicationEditions from './publicationEdition'
import * as publicationInspections from './publicationInspection'
import * as publicationManager from './publicationManager'
import * as publicationOrders from './publicationOrder'
import * as publications from './publication'
import * as reviews from './review'
import * as roles from './role'
import * as savedArtworkPages from './savedArtworkPage'
import * as savedArtworks from './savedArtwork'
import * as scheduledReminders from './scheduledReminder'
import * as serviceCategories from '../../../admin/redux/modules/serviceCategory'
import * as services from './service'
import * as settings from './settings'
import * as shareLinks from './shareLink'
import * as statuses from './status'
import * as subscriber from './subscriber'
import * as tags from './tag'
import * as taskListItems from './taskListItem'
import * as taskListSections from './taskListSection'
import * as taskListTemplateItems from './taskListTemplateItem'
import * as taskListTemplateSections from './taskListTemplateSection'
import * as taskListTemplates from './taskListTemplate'
import * as taskLists from './taskList'
import * as teamMembers from './teamMember'
import * as teams from './team'
import * as templatePageCategories from './templatePageCategory'
import * as templatePageItems from './templatePageItem'
import * as templatePages from './templatePage'
import * as trackingLinks from './trackingLink'
import * as userVerifications from './userVerification'
import * as users from './user'

const modules = {
  activities,
  adCampaigns,
  adCopies,
  addresses,
  adminDashboard,
  agencies,
  agencyContacts,
  agencyDashboard,
  agencyManager,
  agencyMarketing,
  agencyPaymentMethods,
  agencyPortals,
  agencyProviderPriceOverrides,
  agencyTags,
  analytics,
  apiKeys,
  artworkBuilder,
  artworkComments,
  artworkPageItems,
  artworkPages,
  artworkReviews,
  artworks,
  assetLibraries,
  attachments,
  bookings,
  brandGroupPublications,
  brands,
  campaignCategories,
  campaignCommercialCategories,
  campaignComparables,
  campaignContacts,
  campaignContributions,
  campaignDocuments,
  campaignInspections,
  campaignItems,
  campaignNotes,
  campaignPortalSyncs,
  campaignPortals,
  campaignProposalSections,
  campaignProposals,
  campaignQuoteItems,
  campaignQuoteSections,
  campaignQuoteTemplateItems,
  campaignQuoteTemplates,
  campaignQuotes,
  campaignRuralCategories,
  campaignSaleMethods,
  campaignStatuses,
  campaignTypes,
  campaigns,
  caseStudies,
  comments,
  contacts,
  conversations,
  creditNoteItems,
  creditNotes,
  currentUser,
  customDeadlines,
  customFieldSets,
  customItemGroups,
  customItems,
  customShortcodes,
  dataStoreItems,
  defaultQuoteItems,
  digitalComponents,
  digitalPageManager,
  digitalPages,
  digitalTemplates,
  documentGroups,
  documentSigners,
  entities,
  events,
  favouriteOrders,
  financeApplications,
  geoCountries,
  geoSuburbs,
  imageLibraries,
  images,
  integrationData,
  integrations,
  interactiveImageTags,
  interactiveImages,
  invoiceItems,
  invoices,
  layoutTemplates,
  mediaLinks,
  micrositeTemplates,
  microsites,
  mobileDashboard,
  notifications,
  orderBundles,
  orderItemTaskTypes,
  orderItemTasks,
  orderItems,
  orders,
  paymentMethods,
  portals,
  proposalManager,
  providerAccounts,
  providerPackages,
  providerPriceListItems,
  providerPriceListProductUnlocks,
  providerPriceLists,
  providers,
  publicationBookingItems,
  publicationBookings,
  publicationEditions,
  publicationInspections,
  publicationManager,
  publicationOrders,
  publications,
  reviews,
  roles,
  savedArtworkPages,
  savedArtworks,
  scheduledReminders,
  serviceCategories,
  services,
  settings,
  shareLinks,
  statuses,
  subscriber,
  tags,
  taskListItems,
  taskListSections,
  taskListTemplateItems,
  taskListTemplateSections,
  taskListTemplates,
  taskLists,
  teamMembers,
  teams,
  templatePageCategories,
  templatePageItems,
  templatePages,
  trackingLinks,
  userVerifications,
  users,
}

const keys = Object.keys(modules)
export const reducers = keys.reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
