import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/templatePage/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/templatePage/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/templatePage/FETCH_FAILURE'
const UPDATE_REQUEST = 'realhub/templatePage/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/templatePage/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/templatePage/UPDATE_FAILURE'

// Initial State
const initialState = {
  loaded: false,
  loadedForKeys: [],
  loadedProducts: [],
  loadedTagIds: [],
  loading: false,
  updating: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedTagIds: payload.loadedTagIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loadedProducts: payload.loadedProducts,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(payload = {}){
  return {
    type: UPDATE_SUCCESS,
    result: payload.result,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadTemplatePages(options = {}){
  return (dispatch, getState) => {
    const { tag_id, query_key } = options
    const { templatePages } = getState()

    const loadedForKeys = [...templatePages.loadedForKeys]
    if (query_key && !loadedForKeys.includes(query_key)){
      loadedForKeys.push(query_key)
    }

    const loadedTagIds = [...templatePages.loadedTagIds]
    if (tag_id && !loadedTagIds.includes(tag_id)){
      loadedTagIds.push(tag_id)
    }

    dispatch(fetchRequest({ loadedForKeys, loadedTagIds }))

    const promise = api('/agency/template_pages.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const loadedProducts = [...templatePages.loadedProducts]
        if (options.service_product_id){
          loadedProducts.push(options.service_product_id)
        }

        const normalizedJson = normalize(json, Schemas.TEMPLATE_PAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ loadedProducts }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadTemplatePage(templatePageId, options, callback){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/agency/template_pages/${templatePageId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateTemplatePage(templatePage, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      template_page: templatePage,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/template_pages/${templatePage.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedTagIds: action.loadedTagIds || state.loadedTagIds,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loadedProducts: action.loadedProducts,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
