import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/provider/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/provider/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/provider/FETCH_FAILURE'

const SELECT_PROVIDER = 'realhub/provider/SELECT_PROVIDER'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  selectedId: null,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function selectProviderId(providerId){
  return (dispatch, getState) => {
    const state = getState()
    const { providers } = state.entities

    const selectedProvider = providers[providerId]

    dispatch({
      type: SELECT_PROVIDER,
      selectedId: providerId,
      selected: selectedProvider,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function hydrateProvider(provider){
  const normalizedJson = normalize(provider, Schemas.PROVIDER)

  return (dispatch) => {
    dispatch(updateEntities(normalizedJson))
  }
}

export function loadProviders(options, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/providers.json', options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PROVIDER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case SELECT_PROVIDER:
      return {
        ...state,
        selectedId: action.selectedId,
      }
    default:
      return state
  }
}
