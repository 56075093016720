import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'
import { updateCreditNoteTotal } from './creditNote'

const FETCH_REQUEST = 'realhub/creditNoteItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/creditNoteItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/creditNoteItem/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/creditNoteItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/creditNoteItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/creditNoteItem/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/creditNoteItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/creditNoteItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/creditNoteItem/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/creditNoteItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/creditNoteItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/creditNoteItem/DELETE_FAILURE'

// Initial State
const initialState = {
  loading: false,
  loaded: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
    errors: [],
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
    errors: [],
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
    deleting: false,
    errors: [],
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    deleting: false,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createCreditNoteItem(creditNoteItem, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      credit_note_item: creditNoteItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const { credit_note_id } = creditNoteItem
    const promise = api(`/agency/credit_notes/${credit_note_id}/items.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        // Update Credit Note Total
        if (json.credit_note_total){
          dispatch(updateCreditNoteTotal(json.credit_note_id, json.credit_note_total))
        }

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updateCreditNoteItem(creditNoteItem, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      credit_note_item: creditNoteItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { credit_note_id, id } = creditNoteItem
    const promise = api(`/agency/credit_notes/${credit_note_id}/items/${id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Credit Note Total
        if (json.credit_note_total){
          dispatch(updateCreditNoteTotal(json.credit_note_id, json.credit_note_total))
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deleteCreditNoteItem(creditNoteItem){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const { credit_note_id, id } = creditNoteItem
    const promise = api(`/agency/credit_notes/${credit_note_id}/items/${id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Credit Note Total
        if (json.credit_note_total){
          dispatch(updateCreditNoteTotal(creditNoteItem.credit_note_id, json.credit_note_total))
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case UPDATE_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      })
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        errors: [],
      })
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        errors: action.errors,
      })
    case DELETE_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      })
    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
      })
    case DELETE_FAILURE:
      return Object.assign({}, state, {
        deleting: true,
        errors: action.errors,
      })
    default:
      return state
  }
}
