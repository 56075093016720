const CHANGE_MULTIPLE_FILTERS = 'realhub/agencyManager/agency/templatePageFilters/CHANGE_MULTIPLE_FILTERS'

// Initial State
const initialState = {
  entityDefinitions: null,
}

// Actions
export function changeMultipleFilters(filters = {}){
  return {
    type: CHANGE_MULTIPLE_FILTERS,
    filters,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, filters } = action

  switch (type){
    case CHANGE_MULTIPLE_FILTERS:{
      return {
        ...state,
        ...filters,
      }
    }
    default:
      return state
  }
}
