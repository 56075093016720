import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'
import deepmerge from 'deepmerge'

import api from 'api'

const FETCH_REQUEST = 'realhub/campaignQuote/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaignQuote/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaignQuote/FETCH_FAILURE'

const CREATE_REQUEST = 'realhub/campaignQuote/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignQuote/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignQuote/CREATE_FAILURE'

const UPDATE_REQUEST = 'realhub/campaignQuote/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaignQuote/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaignQuote/UPDATE_FAILURE'

const DELETE_REQUEST = 'realhub/campaignQuote/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignQuote/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignQuote/DELETE_FAILURE'

const SHARE_REQUEST = 'realhub/campaignQuote/SHARE_REQUEST'
const SHARE_SUCCESS = 'realhub/campaignQuote/SHARE_SUCCESS'
const SHARE_FAILURE = 'realhub/campaignQuote/SHARE_FAILURE'

const APPROVE_REQUEST = 'realhub/campaignQuote/APPROVE_REQUEST'
const APPROVE_SUCCESS = 'realhub/campaignQuote/APPROVE_SUCCESS'
const APPROVE_FAILURE = 'realhub/campaignQuote/APPROVE_FAILURE'

const SELECT_CAMPAIGN_QUOTE = 'realhub/campaignQuote/SELECT_CAMPAIGN_QUOTE'

// Initial State
const initialState = {
  approving: false,
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForEntities: [],
  loadedIds: [],
  loading: false,
  result: [],
  selectedId: null,
  sharing: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function approveRequest(){
  return {
    type: APPROVE_REQUEST,
    approving: true,
  }
}

export function approveSuccess(){
  return {
    type: APPROVE_SUCCESS,
    approving: false,
  }
}

export function approveFailure(errors = []){
  return {
    type: APPROVE_FAILURE,
    approving: false,
    errors,
  }
}

export function shareRequest(){
  return {
    type: SHARE_REQUEST,
  }
}

export function shareSuccess(){
  return {
    type: SHARE_SUCCESS,
  }
}

export function shareFailure(errors = []){
  return {
    type: SHARE_FAILURE,
    errors,
  }
}

export function selectCampaignQuote(campaignQuote){
  return (dispatch) => {
    dispatch({
      type: SELECT_CAMPAIGN_QUOTE,
      selectedId: campaignQuote.id,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCampaignQuotes(options = {}){
  const entityKey = options.entityKey || `Campaign${options.campaign_id}`

  return (dispatch, getState) => {
    const { campaignQuotes } = getState()
    const loadedForEntities = [...campaignQuotes.loadedForEntities]

    if (options.campaign_id && !loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForEntities: loadedForEntities || null }))

    const promise = api('/agency/campaign_quotes.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadCampaignQuote(campaignQuoteId, options, callback){
  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = state.campaignQuotes.loadedIds ? state.campaignQuotes.loadedIds.slice() : []
    if (!loadedIds.includes(campaignQuoteId)){
      loadedIds.push(campaignQuoteId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/campaign_quotes/${campaignQuoteId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createCampaignQuote(campaignQuote, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_quote: campaignQuote,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/campaign_quotes.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createQuoteFromTemplate(campaignId, campaignQuoteTemplateId, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      campaign_quote: {
        campaign_id: campaignId,
      },
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(
      `/agency/campaign_quote_templates/${campaignQuoteTemplateId}/create_quote.json`,
      options,
      config,
    ) // eslint-disable-line max-len
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = [...getState().campaignQuotes.result]
        resultArray.push(json.id)

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createQuoteFromExisting(campaignId, existingQuoteId, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      campaign_id: campaignId,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/campaign_quotes/${existingQuoteId}/copy_to_campaign.json`, options, config) // eslint-disable-line max-len
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCampaignQuote(campaignQuote, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign_quote: campaignQuote,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function upgradeCampaignQuoteVersion(campaignQuote, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/upgrade_version.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function updateCampaignQuoteTotal(campaignQuoteId, total){
  return (dispatch, getState) => {
    const state = getState()
    const quotes = state.entities.campaignQuotes

    const quote = quotes[campaignQuoteId]

    if (quote){
      const updatedQuote = { ...quote, total }
      const normalizedJson = normalize(updatedQuote, Schemas.CAMPAIGN_QUOTE)
      dispatch(updateEntities(normalizedJson))
      dispatch(updateSuccess())
    } else {
      dispatch(updateFailure(['Quote does not exist.']))
    }
  }
}

export function shareCampaignQuote(campaignQuote, emails, message, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      emails,
      message,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(shareRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/send_quote.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(shareSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(shareFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function validateCampaignQuotePrices(campaignQuote, options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/validate_prices.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return { success: true, result: json.data }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: true, result: errors }
      })

    return promise
  }
}

export function approveCampaignQuote(campaignQuote, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign_quote: campaignQuote,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(approveRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/approve.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Quote
        const normalizedQuoteJson = normalize(json.campaign_quote, Schemas.CAMPAIGN_QUOTE)

        // Add Orders
        const normalizedOrderJson = normalize(json.orders, Schemas.ORDER_ARRAY)

        // Add Campaign Items
        const normalizedCampaignItemJson = normalize(json.campaign_items, Schemas.CAMPAIGN_ITEM_ARRAY)

        // Add Invoice
        const normalizedInvoiceJson = normalize(json.invoice, Schemas.INVOICE)

        const normalizedJson = deepmerge.all([
          normalizedQuoteJson,
          normalizedOrderJson,
          normalizedCampaignItemJson,
          normalizedInvoiceJson,
        ])

        dispatch(updateEntities(normalizedJson))
        dispatch(approveSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(approveFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteCampaignQuote(campaignQuote, options){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createQuoteSignatureRequest(campaignQuote, options, callback){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/signature_request.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function generateQuoteSigningUrl(campaignQuote, options, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/signing_url.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function deauthorizeCampaignQuote(campaignQuote, options, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/deauthorize.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function campaignQuotesApiImport(campaign, options = {}){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/quotes/api_import.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result || state.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result || state.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        result: action.result || state.result,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result || state.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case APPROVE_REQUEST:
      return {
        ...state,
        approving: true,
      }
    case APPROVE_SUCCESS:
      return {
        ...state,
        approving: false,
        errors: [],
      }
    case APPROVE_FAILURE:
      return {
        ...state,
        approving: false,
        errors: action.errors,
      }
    case SHARE_REQUEST:
      return {
        ...state,
        sharing: true,
      }
    case SHARE_SUCCESS:
      return {
        ...state,
        sharing: false,
        errors: [],
      }
    case SHARE_FAILURE:
      return {
        ...state,
        sharing: false,
        errors: action.errors,
      }
    case SELECT_CAMPAIGN_QUOTE:
      return {
        ...state,
        selectedId: action.selectedId,
      }
    default:
      return state
  }
}
