import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const CREATE_REQUEST = 'realhub/publicationOrder/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/publicationOrder/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/publicationOrder/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/publicationOrder/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/publicationOrder/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/publicationOrder/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  updating: false,
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createPublicationOrder(publicationOrder, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      publication_order: publicationOrder,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    // Encode Params
    const promise = api('/agency/publication_orders.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function autoPaginateOrder(publicationEdition, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      edition_id: publicationEdition.id,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    // Encode Params
    const promise = api('/agency/publication_orders/auto_paginate.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function deletePublicationOrderBookings(publicationOrder, options, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Encode Params
    const promise = api(`/agency/publication_orders/${publicationOrder.id}/delete_bookings.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Delete Bookings
        const bookings = getState().entities.publicationBookings
        const keys = Object.keys(bookings)
        keys.forEach((id) => {
          const booking = bookings[id]
          if (booking.publication_order_id === publicationOrder.id){
            const normalizedJson = normalize(booking, Schemas.PUBLICATION_BOOKING)
            dispatch(deleteEntity(normalizedJson))
          }
        })

        const normalizedJson = normalize(json, Schemas.PUBLICATION_ORDER)
        dispatch(updateEntities(normalizedJson))

        if (callback) callback(json)
      })

    return promise
  }
}

export function checkRemovedBookingItems(publicationOrderId, options, callback){
  return () => {
    const promise = api(`/agency/publication_orders/${publicationOrderId}/removed_booking_items.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        if (callback) callback(json)
      })

    return promise
  }
}

export function revertPublicationOrder(publicationOrder, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const promise = api(`/agency/publication_orders/${publicationOrder.id}/revert.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function bulkRelease(publicationOrder, options, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const promise = api(`/agency/publication_orders/${publicationOrder.id}/bulk_release.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case UPDATE_REQUEST:
      return Object.assign({}, state, {
        updating: true,
      })
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updating: false,
        errors: [],
      })
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        updating: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
