import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/agencyTag/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/agencyTag/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/agencyTag/FETCH_FAILURE'

const CREATE_REQUEST = 'realhub/agencyTag/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/agencyTag/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/agencyTag/CREATE_FAILURE'

const DELETE_REQUEST = 'realhub/agencyTag/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/agencyTag/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/agencyTag/DELETE_FAILURE'

// Initial State
const initialState = {
  selectedId: null,
  loaded: false,
  loading: false,
  creating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadAgencyTags(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/agency/agency_tags.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY_TAG_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createAgencyTag(agencyTag, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      agency_tag: agencyTag,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/agency_tags.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY_TAG)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })

      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteAgencyTag(agencyTag, options, callback){
  const config = {
    method: 'DELETE',
    body: JSON.stringify({
      agency_tag: agencyTag,
    }),
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const { id } = agencyTag
    const promise = api(`/agency/agency_tags/${id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.AGENCY_TAG)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback) callback(json)
        
        return { success: true, result: json }
    })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      
        return { success: false, result: errors }
    })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case DELETE_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      })
    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
        errors: [],
      })
    case DELETE_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
