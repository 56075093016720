import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/providerPriceListProductUnlock/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/providerPriceListProductUnlock/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/providerPriceListProductUnlock/FETCH_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loadedKeys: [],
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loading: true,
    loadedKeys: payload.loadedKeys,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}

// Action Creators
export function loadProductUnlocks(key, options){
  return (dispatch, getState) => {
    const unlocks = getState().providerPriceListProductUnlocks

    // Set Loading
    const loadedKeys = [...unlocks.loadedKeys]
    const index = loadedKeys.indexOf(key)
    if (index === -1){
      loadedKeys.push(key)
    }
    dispatch(fetchRequest({ loadedKeys }))

    const promise = api('/agency/provider_price_list_product_unlocks.json', options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PROVIDER_PRICE_LIST_PRODUCT_UNLOCK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = json.errors ? json.errors : ['Something went wrong']
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedKeys: action.loadedKeys || state.loadedKeys,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
