import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'
import api from 'api'

const FETCH_REQUEST = 'realhub/activity/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/activity/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/activity/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedActivityForEntities: [],
  loading: false,
  result: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loading: true,
    loadedActivityForEntities: payload.loadedActivityForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadActivities(options){
  const { recipient_id, recipient_type } = options || {}
  const entityKey = options.entityKey || `${recipient_type}${recipient_id}`

  return (dispatch, getState) => {
    // Set Loading
    const loadedForEntities = [...getState().activities.loadedActivityForEntities]
    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    dispatch(fetchRequest({ loadedActivityForEntities: loadedForEntities }))

    const promise = api('/agency/activities.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ACTIVITY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadEntityActivity(entityType, entityId, options, callback){
  const entityKey = `${entityType}${entityId}`

  let url
  switch (entityType){
    case 'Artwork':
      url = `/agency/artworks/${entityId}/activity.json`
      break
    case 'OrderItem':
      url = `/agency/order_items/${entityId}/activity.json`
      break
    default:
      url = `/agency/campaigns/${entityId}/activity.json`
      break
  }

  return (dispatch, getState) => {
    // Set Loading
    const loadedActivityForEntities = getState().activities.loadedActivityForEntities
      ? getState().activities.loadedActivityForEntities.slice() : []

    const index = loadedActivityForEntities.indexOf(entityKey)
    if (index === -1){
      loadedActivityForEntities.push(entityKey)
    }
    dispatch(fetchRequest({ loadedActivityForEntities }))

    const promise = api(url, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ACTIVITY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = json.errors ? json.errors : ['Something went wrong...']
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedActivityForEntities: action.loadedActivityForEntities || state.loadedActivityForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
