import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/digitalPage/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/digitalPage/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/digitalPage/FETCH_FAILURE'

const CREATE_REQUEST = 'realhub/digitalPage/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/digitalPage/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/digitalPage/CREATE_FAILURE'

const UPDATE_REQUEST = 'realhub/digitalPage/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/digitalPage/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/digitalPage/UPDATE_FAILURE'

const DELETE_REQUEST = 'realhub/digitalPage/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/digitalPage/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/digitalPage/DELETE_FAILURE'

const SHARE_REQUEST = 'realhub/digitalPage/SHARE_REQUEST'
const SHARE_SUCCESS = 'realhub/digitalPage/SHARE_SUCCESS'
const SHARE_FAILURE = 'realhub/digitalPage/SHARE_FAILURE'

const SELECT_DIGITAL_PAGE = 'realhub/digitalPage/SELECT_DIGITAL_PAGE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForEntities: [],
  loadedIds: [],
  loading: false,
  selectedId: null,
  sharing: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectDigitalPage({ id }){
  return {
    type: SELECT_DIGITAL_PAGE,
    selectedId: id,
  }
}

export function shareRequest(){
  return {
    type: SHARE_REQUEST,
  }
}

export function shareSuccess(){
  return {
    type: SHARE_SUCCESS,
  }
}

export function shareFailure(errors = []){
  return {
    type: SHARE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function hydrateDigitalPage(json){
  return (dispatch) => {
    dispatch(fetchRequest())

    if (json.id){
      const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess(normalizedJson))
    } else {
      dispatch(fetchFailure(['Could not hydrate undefined digital page.']))
    }
  }
}

export function loadDigitalPages(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForEntities = [...getState().digitalPages.loadedForEntities]
    if (entityKey && !loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/digital_pages.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadDigitalPage(digitalPageId, options){
  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = [...state.digitalPages.loadedIds]
    if (!loadedIds.includes(digitalPageId)){
      loadedIds.push(digitalPageId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agency/digital_pages/${digitalPageId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createDigitalPage(digitalPage, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      digital_page: digitalPage,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/digital_pages.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createOrUpdateCustomImage(digitalPage, image, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      image_id: image.id,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(
      `/agency/digital_pages/${digitalPage.id}/create_or_update_custom_image.json`,
      options,
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createFromProposal(digitalPage, campaignProposal, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_proposal: campaignProposal,
      digital_page: digitalPage,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/digital_pages/create_from_proposal.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ATTACHMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateDigitalPage(digitalPage, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      digital_page: digitalPage,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/digital_pages/${digitalPage.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function resetCustomImages(digitalPage, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/digital_pages/${digitalPage.id}/reset_custom_images.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const { ids } = json

        if (ids && Array.isArray(ids)){
          ids.forEach((id) => {
            const normalizedJson = normalize({ id }, Schemas.ATTACHMENT)
            dispatch(deleteEntity(normalizedJson))
          })

          dispatch(updateSuccess())
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteDigitalPage(digitalPage){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/agency/digital_pages/${digitalPage.id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function shareDigitalPage(digitalPage, mobileNumbers = [], message = ''){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      mobile_numbers: mobileNumbers,
      message,
    }),
  }

  return (dispatch) => {
    dispatch(shareRequest())

    const promise = api(`/agency/digital_pages/${digitalPage.id}/share.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(shareSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(shareFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function createDigitalPageWithCampaign(digitalPage, campaign, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign,
      digital_page: digitalPage,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/digital_pages/create_with_campaign.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: json }
      })

    return promise
  }
}

export function buildDigitalPagePdf(digitalPage, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/digital_pages/${digitalPage.id}/build_pdf.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case SELECT_DIGITAL_PAGE:
      return {
        ...state,
        selectedId: action.selectedId,
      }
    case SHARE_REQUEST:
      return {
        ...state,
        sharing: true,
      }
    case SHARE_SUCCESS:
      return {
        ...state,
        sharing: false,
        errors: [],
      }
    case SHARE_FAILURE:
      return {
        ...state,
        sharing: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
