import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/creditNote/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/creditNote/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/creditNote/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/creditNote/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/creditNote/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/creditNote/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/creditNote/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/creditNote/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/creditNote/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/creditNote/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/creditNote/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/creditNote/DELETE_FAILURE'

const SELECT_CREDIT_NOTE = 'realhub/creditNote/SELECT_CREDIT_NOTE'

// Initial State
const initialState = {
  selectedId: null,
  loading: false,
  loaded: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
    errors: [],
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
    errors: [],
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
    deleting: false,
    errors: [],
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    deleting: false,
    errors,
  }
}

export function selectCreditNote(creditNoteId){
  return (dispatch) => {
    dispatch({
      type: SELECT_CREDIT_NOTE,
      selectedId: creditNoteId,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCreditNotes(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/agency/credit_notes.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createCreditNote(creditNote, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      credit_note: creditNote,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/credit_notes.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCreditNoteTotal(creditNoteId, total){
  return (dispatch, getState) => {
    const state = getState()
    const { creditNotes } = state.entities

    const creditNote = creditNotes[creditNoteId]

    if (creditNote){
      const updated = { ...creditNote, total }
      const normalizedJson = normalize(updated, Schemas.CREDIT_NOTE)
      dispatch(updateEntities(normalizedJson))
      dispatch(updateSuccess())
    } else {
      dispatch(updateFailure(['Credit Note does not exist.']))
    }
  }
}

export function updateCreditNote(creditNote, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      credit_note: creditNote,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { id } = creditNote
    const promise = api(`/agency/credit_notes/${id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function createExternalCreditNote(creditNote, options = {}, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/credit_notes/${creditNote.id}/create_external_credit_note.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteCreditNote(creditNote){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    const { id } = creditNote
    const promise = api(`/agency/credit_notes/${id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CREDIT_NOTE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: errors, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return { ...state, deleting: false }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: true,
        errors: action.errors,
      }
    case SELECT_CREDIT_NOTE:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
