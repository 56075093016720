import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const CREATE_REQUEST = 'realhub/comment/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/comment/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/comment/CREATE_FAILURE'

const initialState = {
  creating: false,
  errors: [],
}

// Actions

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function hydrateComment(comment){
  return (dispatch) => {
    dispatch(createRequest())

    if (comment.id){
      const normalizedJson = normalize(comment, Schemas.COMMENT)
      dispatch(updateEntities(normalizedJson))
      dispatch(createSuccess())
    } else {
      dispatch(createFailure(['Could not hydrate undefined comment.']))
    }
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function createComment(comment, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      comment,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/comments.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.COMMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        errors: [],
      })
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
