import { combineReducers } from 'redux'

import accounting from './accounting'
import agency from './agency'
import workflows from './workflows'

export default combineReducers({
  accounting,
  agency,
  workflows,
})
