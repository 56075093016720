import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/publicationInspection/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/publicationInspection/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/publicationInspection/FETCH_FAILURE'

const CREATE_REQUEST = 'realhub/publicationInspection/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/publicationInspection/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/publicationInspection/CREATE_FAILURE'

const UPDATE_REQUEST = 'realhub/publicationInspection/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/publicationInspection/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/publicationInspection/UPDATE_FAILURE'

const DELETE_REQUEST = 'realhub/publicationInspection/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/publicationInspection/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/publicationInspection/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  loaded: false,
  loadedForKeys: [],
  loading: false,
  errors: [],
  updating: false,
  deleting: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadPublicationInspections(options){
  return (dispatch, getState) => {
    const { publicationInspections } = getState()
    const { queryKey } = options
    const loadedForKeys = [...publicationInspections.loadedForKeys]

    if (queryKey && !loadedForKeys.includes(queryKey)){
      loadedForKeys.push(queryKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/agency/publication_inspections.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_INSPECTION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createPublicationInspection(publicationInspection, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      publication_inspection: publicationInspection,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/publication_inspections.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_INSPECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updatePublicationInspection(publicationInspection, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      publication_inspection: publicationInspection,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/publication_inspections/${publicationInspection.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_INSPECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deletePublicationInspection(publicationInspection){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/agency/publication_inspections/${publicationInspection.id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const normalizedJson = normalize(json, Schemas.PUBLICATION_INSPECTION)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })
    return promise
  }
}

export function approvePublicationInspection(publicationInspection, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/publication_inspections/${publicationInspection.id}/approve.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_INSPECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function unapprovePublicationInspection(publicationInspection, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/publication_inspections/${publicationInspection.id}/unapprove.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_INSPECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
