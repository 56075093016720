import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/interactiveImageTag/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/interactiveImageTag/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/interactiveImageTag/FETCH_FAILURE'

const CREATE_REQUEST = 'realhub/interactiveImageTag/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/interactiveImageTag/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/interactiveImageTag/CREATE_FAILURE'

const UPDATE_REQUEST = 'realhub/interactiveImageTag/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/interactiveImageTag/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/interactiveImageTag/UPDATE_FAILURE'

const DELETE_REQUEST = 'realhub/interactiveImageTag/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/interactiveImageTag/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/interactiveImageTag/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  loaded: false,
  loading: false,
  errors: [],
  updating: false,
  deleting: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadInteractiveImageTags(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/agency/interactive_image_tags.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INTERACTIVE_IMAGE_TAG_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createInteractiveImageTag(interactiveImageTag, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      interactive_image_tag: interactiveImageTag,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(
      `/agency/order_items/${interactiveImageTag.order_item_id}/interactive_image_tags.json`,
      options,
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INTERACTIVE_IMAGE_TAG)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updateInteractiveImageTag(interactiveImageTag, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      interactive_image_tag: interactiveImageTag,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(
      `/agency/order_items/${interactiveImageTag.order_item_id}/interactive_image_tags/${interactiveImageTag.id}.json`,
      options,
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INTERACTIVE_IMAGE_TAG)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deleteInteractiveImageTag(interactiveImageTag){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(
      `/agency/order_items/${interactiveImageTag.order_item_id}/interactive_image_tags/${interactiveImageTag.id}.json`,
      {},
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }
        const normalizedJson = normalize(json, Schemas.INTERACTIVE_IMAGE_TAG)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
