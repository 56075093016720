const CHANGE_FILTER = 'realhub/digitalPageManager/dashboard/forms/CHANGE_FILTER'

// Initial State
const initialState = {
  filterStatus: 'all',
  filterString: '',
}

export function changeFilter(name, value){
  return {
    type: CHANGE_FILTER,
    name,
    value,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_FILTER:
      return {
        ...state,
        [action.name]: action.value,
      }
    default:
      return state
  }
}
