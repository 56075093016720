import { normalize } from 'normalizr'
import api from 'api'
import { Schemas } from '../../../shared/redux/schema'
import { updateEntities, deleteEntity } from '../../../shared/redux/entity'

import { updateCampaignQuoteTotal } from './campaignQuote'

const FETCH_REQUEST = 'realhub/campaignQuoteItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaignQuoteItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaignQuoteItem/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/campaignQuoteItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignQuoteItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignQuoteItem/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/campaignQuoteItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaignQuoteItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaignQuoteItem/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaignQuoteItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignQuoteItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignQuoteItem/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// HELPER FUNCTIONS
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCampaignQuoteItems(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/campaign_quote_items.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createCampaignQuoteItem(campaignQuoteItem, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_quote_item: campaignQuoteItem,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuoteItem.campaign_quote_id}/items.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().campaignQuoteItems.result ? getState().campaignQuoteItems.result.slice() : []
        if (!resultArray.includes(json.id)){
          resultArray.push(json.id)
        }

        // Update Quote Data
        if (json.quote_total){
          dispatch(updateCampaignQuoteTotal(json.campaign_quote_id, json.quote_total))
        }

        if (json.quote_dynamic_items){
          const normalizedItems = normalize(json.quote_dynamic_items, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
          dispatch(updateEntities(normalizedItems))
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createMultipleCampaignQuoteItems(orderType, campaignQuoteItem, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_quote_item: campaignQuoteItem,
      order_type: orderType || 'publication',
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(
      `/agency/campaign_quotes/${campaignQuoteItem.campaign_quote_id}/items/create_multiple.json`,
      options,
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Quote Data
        const firstItem = json[0]
        if (firstItem){
          if (firstItem.quote_total){
            dispatch(updateCampaignQuoteTotal(firstItem.campaign_quote_id, firstItem.quote_total))
          }

          if (firstItem.quote_dynamic_items){
            const normalizedItems = normalize(firstItem.quote_dynamic_items, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
            dispatch(updateEntities(normalizedItems))
          }
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function duplicateCampaignQuoteItem(campaignQuoteItem, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const { campaign_quote_id, id } = campaignQuoteItem
    const promise = api(`/agency/campaign_quotes/${campaign_quote_id}/items/${id}/duplicate.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Quote Data
        if (json.quote_total){
          dispatch(updateCampaignQuoteTotal(json.campaign_quote_id, json.quote_total))
        }

        if (json.quote_dynamic_items){
          const normalizedItems = normalize(json.quote_dynamic_items, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
          dispatch(updateEntities(normalizedItems))
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadDynamicQuoteItems(campaignQuote, options, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const { id } = campaignQuote
    const promise = api(`/agency/campaign_quotes/${id}/items/dynamic.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCampaignQuoteItem(campaignQuoteItem, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign_quote_item: campaignQuoteItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { campaign_quote_id, id } = campaignQuoteItem
    const promise = api(`/agency/campaign_quotes/${campaign_quote_id}/items/${id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Quote Total
        if (json.quote_total){
          dispatch(updateCampaignQuoteTotal(json.campaign_quote_id, json.quote_total))
        }

        if (json.quote_dynamic_items){
          const normalizedItems = normalize(json.quote_dynamic_items, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
          dispatch(updateEntities(normalizedItems))
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCampaignQuoteItemOptions(campaignQuoteItem, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      id: campaignQuoteItem.id,
      campaign_quote_item: campaignQuoteItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { campaign_quote_id, id } = campaignQuoteItem
    const promise = api(`/agency/campaign_quotes/${campaign_quote_id}/items/${id}/update_options.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Quote Total
        if (json.quote_total){
          dispatch(updateCampaignQuoteTotal(json.campaign_quote_id, json.quote_total))
        }

        if (json.quote_dynamic_items){
          const normalizedItems = normalize(json.quote_dynamic_items, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
          dispatch(updateEntities(normalizedItems))
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateQuoteItemSortOrder(campaignQuoteSection, quoteItemIds, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sort_order: quoteItemIds,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { campaign_quote_id } = campaignQuoteSection
    const promise = api(`/agency/campaign_quotes/${campaign_quote_id}/items/update_sort_order.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deleteCampaignQuoteItem(campaignQuoteItem, options, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(
      `/agency/campaign_quotes/${campaignQuoteItem.campaign_quote_id}/items/${campaignQuoteItem.id}.json`,
      options,
      config,
    ) // eslint-disable-line max-len
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        let resultArray = getState().campaignQuoteItems.result ? getState().campaignQuoteItems.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray = resultArray.splice(index, 1)
        }

        // Update Quote Total
        if (json.quote_total){
          dispatch(updateCampaignQuoteTotal(json.campaign_quote_id, json.quote_total))
        }

        // Update related items
        if (json.updated_items){
          const normalizedUpdated = normalize(json.updated_items, Schemas.CAMPAIGN_QUOTE_ITEM_ARRAY)
          dispatch(updateEntities(normalizedUpdated))
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_QUOTE_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        result: action.result,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
