import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/campaignContact/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaignContact/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaignContact/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/campaignContact/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignContact/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignContact/CREATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaignContact/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignContact/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignContact/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  creating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCampaignContacts(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/campaign_contacts.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_CONTACT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createCampaignContact(campaignContact, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      v2: true,
      campaign_contact: campaignContact,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/campaign_contacts.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().campaignContacts.result ? getState().campaignContacts.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_CONTACT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteCampaignContact(campaignContact, options){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/campaign_contacts/${campaignContact.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        let resultArray = getState().campaignContacts.result ? getState().campaignContacts.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray = resultArray.splice(index, 1)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_CONTACT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: resultArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
