import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/publicationBooking/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/publicationBooking/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/publicationBooking/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/publicationBooking/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/publicationBooking/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/publicationBooking/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/publicationBooking/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/publicationBooking/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/publicationBooking/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/publicationBooking/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/publicationBooking/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/publicationBooking/DELETE_FAILURE'

const CHECK_ARTWORK_REQUEST = 'realhub/publicationBooking/CHECK_ARTWORK_REQUEST'
const CHECK_ARTWORK_SUCCESS = 'realhub/publicationBooking/CHECK_ARTWORK_SUCCESS'
const CHECK_ARTWORK_FAILURE = 'realhub/publicationBooking/CHECK_ARTWORK_FAILURE'

const SELECT_BOOKING = 'realhub/publicationBooking/SELECT_BOOKING'

// Initial State
const initialState = {
  result: [],
  loadedForEntities: [],
  selectedId: null,
  loaded: false,
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  checkingArtwork: false,
  errors: [],
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function checkArtworkRequest(){
  return {
    type: CHECK_ARTWORK_REQUEST,
  }
}

export function checkArtworkSuccess(){
  return {
    type: CHECK_ARTWORK_SUCCESS,
  }
}

export function checkArtworkFailure(errors = []){
  return {
    type: CHECK_ARTWORK_FAILURE,
    errors,
  }
}

export function selectBooking(publicationBooking = {}){
  return (dispatch) => {
    dispatch({
      type: SELECT_BOOKING,
      selectedId: publicationBooking.id,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadPublicationBookings(options, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/publication_bookings.json', options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadEntityBookings(entityKey, options, callback){
  return (dispatch, getState) => {
    const bookings = getState().publicationBookings
    const loadedForEntities = bookings.loadedForEntities ? bookings.loadedForEntities.slice() : []
    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/publication_bookings.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createPublicationBooking(publicationBooking, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      publication_booking: publicationBooking,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    // Encode Params
    const promise = api('/agency/publication_bookings.json', options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function checkBookingArtworks(publicationBooking, successCallback, busyCallback){
  return (dispatch) => {
    // Set Loading
    dispatch(checkArtworkRequest())

    const promise = api(`/agency/publication_bookings/${publicationBooking.id}/check_artworks.json`)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Check if artwork has been built
        if (json.ready && successCallback){
          successCallback(json)
        } else if (busyCallback){
          busyCallback(json)
        }

        dispatch(checkArtworkSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(checkArtworkFailure(errors))
      })

    return promise
  }
}

export function updatePublicationBooking(publicationBooking, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      publication_booking: publicationBooking,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const url = `/agency/publication_bookings/${publicationBooking.id}.json`
    const promise = api(url, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function updateBookingSortOrder(sortedIds, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sort_order: sortedIds,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const url = '/agency/publication_bookings/update_sort_order.json'
    const promise = api(url, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function approvePublicationBooking(publicationBooking, options, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const url = `/agency/publication_bookings/${publicationBooking.id}/approve.json`
    const promise = api(url, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function unapprovePublicationBooking(publicationBooking, options, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    // Encode Params
    const url = `/agency/publication_bookings/${publicationBooking.id}/unapprove.json`
    const promise = api(url, options, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deletePublicationBooking(publicationBooking, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(deleteRequest())

    // Encode Params
    const url = `/agency/publication_bookings/${publicationBooking.id}.json`
    const promise = api(url, {}, config)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_BOOKING)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case CHECK_ARTWORK_REQUEST:
      return { ...state, checkingArtwork: true }
    case CHECK_ARTWORK_SUCCESS:
      return {
        ...state,
        checkingArtwork: false,
        errors: [],
      }
    case CHECK_ARTWORK_FAILURE:
      return {
        ...state,
        checkingArtwork: false,
        errors: action.errors,
      }
    case SELECT_BOOKING:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
