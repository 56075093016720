import api from 'api'

const UPDATE_INTEGRATION_DATA = 'realhub/integrations/docusign/UPDATE_INTEGRATION_DATA'

// Initial State
const initialState = {
  integrationData: {},
}

// Actions
export function updateIntegrationData(data){
  return {
    type: UPDATE_INTEGRATION_DATA,
    data,
  }
}

export function loadIntegrationData(){
  return (dispatch) => {
    const promise = api('/integrations/docusign/data.json')
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(updateIntegrationData(json.integration))

        return { success: true, result: json }
      })
      .catch(() => {
        const data = {}
        dispatch(updateIntegrationData(data))

        return { success: false, result: data }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case UPDATE_INTEGRATION_DATA:
      return { ...state, integrationData: action.data }
    default:
      return state
  }
}
