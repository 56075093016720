import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

import { clearArtworkPageItemRender, deleteRendersForArtworkDataKeys, renderArtworkPageItem } from './artworkPageItem'

const FETCH_REQUEST = 'realhub/artworkPage/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/artworkPage/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/artworkPage/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/artworkPage/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/artworkPage/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/artworkPage/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/artworkPage/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/artworkPage/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/artworkPage/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/artworkPage/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/artworkPage/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/artworkPage/DELETE_FAILURE'

const SELECT_ARTWORK_PAGE = 'realhub/artworkPage/SELECT_ARTWORK_PAGE'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  loaded: false,
  loadedForArtworkIds: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForArtworkIds: payload.loadedForArtworkIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectArtworkPage(artworkPage){
  return (dispatch, getState) => {
    const state = getState()
    const { artworkPages } = state.entities

    const selectedArtworkPage = artworkPages[artworkPage.id]

    dispatch({
      type: SELECT_ARTWORK_PAGE,
      selectedId: artworkPage.id,
      selected: selectedArtworkPage,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadArtworkPages(artworkId, options){
  return (dispatch, getState) => {
    // Add to loaded array
    const state = getState()
    const loadedForArtworkIds = state.artworkPages.loadedForArtworkIds
      ? state.artworkPages.loadedForArtworkIds.slice()
      : []
    if (!loadedForArtworkIds.includes(artworkId)){
      loadedForArtworkIds.push(artworkId)
    }

    dispatch(fetchRequest({ loadedForArtworkIds }))

    const promise = api(`/agency/artworks/${artworkId}/pages.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createArtworkPage(artwork, templatePage, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      artwork_page: {
        artwork_id: artwork.id,
        template_page_id: templatePage.id,
      },
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    // Encode Params
    const promise = api(`/agency/order_items/${artwork.order_item_id}/artwork_pages.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().artworkPages.result ? getState().artworkPages.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        // Add page to artwork
        const updatedArtwork = getState().entities.artworks[artwork.id]
          ? ({ ...getState().entities.artworks[artwork.id] })
          : {}

        if (!updatedArtwork.id){
          return Promise.reject({ errors: ['Artwork not found'] })
        }
        updatedArtwork.pages.push(json.id)
        const normalizedArtwork = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedArtwork))

        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function createArtworkPageFromSaved(orderItem, artwork, savedArtworkPage, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      artwork_id: artwork.id,
      order_item_id: orderItem.id,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const url = `/agency/saved_artwork_pages/${savedArtworkPage.id}/restore_as_page.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().artworkPages.result ? getState().artworkPages.result.slice() : []
        resultArray.push(json.id)

        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function modifyArtworkPageData(artworkPage, templatePageItem, value){
  return (dispatch) => {
    const updatedArtworkPage = JSON.parse(JSON.stringify(artworkPage))
    updatedArtworkPage.data[templatePageItem.artwork_data_key] = value

    const normalizedJson = normalize(updatedArtworkPage, Schemas.ARTWORK_PAGE)
    dispatch(updateEntities(normalizedJson))
  }
}

export function updateArtworkPageData(orderItem, artworkPage, templatePageItem, value){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      artwork_page: {
        data: JSON.stringify({
          key: templatePageItem.artwork_data_key,
          value,
        }),
      },
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/artwork_pages/${artworkPage.id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        if (templatePageItem.shouldRender){
          // Clear the old Render
          dispatch(clearArtworkPageItemRender(artworkPage, templatePageItem.render))

          // Generate a new Render
          dispatch(renderArtworkPageItem(artworkPage, templatePageItem.render))
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function updateArtworkPageIconSet(orderItem, artworkPage, icons, parent){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      artwork_page: {
        icons: JSON.stringify(icons),
      },
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(
      `/agency/order_items/${orderItem.id}/artwork_pages/${artworkPage.id}/update_icon_set.json`,
      {},
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update the icon box
        dispatch(updateArtworkPageData(orderItem, artworkPage, parent.templatePageItem, parent.value))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function updateArtworkPageOverlayData(orderItem, artworkPage, templatePageItem, itemData){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      artwork_page: {
        data: JSON.stringify(itemData),
      },
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(updateRequest())

    const url = `/agency/order_items/${orderItem.id}/artwork_pages/${artworkPage.id}/update_overlay.json`
    const promise = api(url, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Get Entity
        const updatedPage = getState().entities.artworkPages[artworkPage.id]
          ? ({ ...getState().entities.artworkPages[artworkPage.id] })
          : {}

        if (!updatedPage.id){
          return Promise.reject({ errors: ["Artwork Page doesn't exist."] })
        }

        // Update the data in our object
        const keys = Object.keys(itemData)
        for (let i = 0; i < keys.length; i++){
          const key = keys[i]
          updatedPage.data[key] = itemData[key]
        }

        // Clear the old Render
        dispatch(clearArtworkPageItemRender(artworkPage, templatePageItem.render))

        // Generate a new Render
        dispatch(renderArtworkPageItem(artworkPage, templatePageItem.render))

        // Update Entity
        const normalizedJson = normalize(updatedPage, Schemas.ARTWORK_PAGE)
        dispatch(updateEntities(normalizedJson))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function updateArtworkPageSortOrder(artwork, pageIds, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sort_order: pageIds,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { id } = artwork
    const promise = api(`/agency/artworks/${id}/pages/update_sort_order.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function updateArtworkPageItemDataSource(orderItem, artworkPage, templatePageItem, entity){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      entity_id: entity.id,
      entity_type: entity.type,
      template_page_item_id: templatePageItem.id,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const url = `/agency/order_items/${orderItem.id}/artwork_pages/${artworkPage.id}/change_data_source.json`
    const promise = api(url, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Clear Renders
        if (json.updated_data_keys){
          dispatch(deleteRendersForArtworkDataKeys(artworkPage, json.updated_data_keys))
        }

        // Update Entity
        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE)
        dispatch(updateEntities(normalizedJson))

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function deleteArtworkPage(artwork, artworkPage, options, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const url = `/agency/order_items/${artwork.order_item_id}/artwork_pages/${artworkPage.id}.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        let resultArray = getState().artworkPages.result ? getState().artworkPages.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray = resultArray.splice(index, 1)
        }

        // Remove page from artwork
        const updatedArtwork = getState().entities.artworks[artworkPage.artwork_id]
          ? ({ ...getState().entities.artworks[artworkPage.artwork_id] })
          : {}

        if (!updatedArtwork.id){
          return Promise.reject({ errors: ['Artwork not found'] })
        }
        const pageIndex = updatedArtwork.pages.indexOf(json.id)
        if (pageIndex !== -1){
          updatedArtwork.pages = updatedArtwork.pages.splice(pageIndex, 1)
        }

        const normalizedArtwork = normalize(json, Schemas.ARTWORK)
        dispatch(updateEntities(normalizedArtwork))

        const normalizedJson = normalize(artworkPage, Schemas.ARTWORK_PAGE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: resultArray }))

        if (callback){
          callback(json)
        }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

export function renderArtworkPagePreview(artworkPage, orderItem, options = {}, callback){
  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/artwork_pages/${artworkPage.id}/preview.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ARTWORK_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForArtworkIds: action.loadedForArtworkIds || state.loadedForArtworkIds,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case SELECT_ARTWORK_PAGE:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
