import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'
import { removeOrderId, updateOrderTotal, updateOrderEdition } from './order'

const FETCH_REQUEST = 'realhub/orderItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/orderItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/orderItem/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/orderItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/orderItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/orderItem/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/orderItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/orderItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/orderItem/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/orderItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/orderItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/orderItem/DELETE_FAILURE'
const SELECT_ORDER_ITEM = 'realhub/orderItem/SELECT_ORDER_ITEM'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  loaded: false,
  loadedForEntities: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
    updating: false,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectOrderItem(orderItem){
  return (dispatch, getState) => {
    const state = getState()
    const { orderItems } = state.entities

    const selectedOrderItem = orderItems[orderItem.id]

    dispatch({
      type: SELECT_ORDER_ITEM,
      selectedId: orderItem.id,
      selected: selectedOrderItem,
    })
  }
}

// HELPER FUNCTIONS
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadOrderItem(orderItemId, options){
  return (dispatch, getState) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api(`/agency/order_items/${orderItemId}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const resultArray = getState().orderItems.result ? getState().orderItems.result.slice() : []
        if (!resultArray.includes(json.id)){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: resultArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadOrderItems(options){
  const { entityKey } = options || {}
  return (dispatch, getState) => {
    const loadedForEntities = [...getState().orderItems.loadedForEntities]
    if (entityKey && !loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/order_items.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function addOrderItemArtwork(orderItemId, artwork){
  return (dispatch, getState) => {
    const { orderItems } = getState().entities
    const orderItem = orderItems[orderItemId]

    if (orderItem){
      const updatedOrderItem = {
        ...orderItem,
        artwork_id: artwork.id,
        artwork,
      }

      const normalizedJson = normalize(updatedOrderItem, Schemas.ORDER_ITEM)
      dispatch(updateEntities(normalizedJson))
      dispatch(updateSuccess())

      return { success: true, result: updatedOrderItem }
    }

    const errors = ['Could not add artwork to unknown item']
    dispatch(updateFailure(errors))
    return { success: false, result: errors }
  }
}

export function assignOrderItemArtwork(orderItem, artwork, options, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/assign_artwork/${artwork.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Normalize
        const normalizedJson = normalize(json, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateOrderItem(orderItem, options = {}, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      order_item: orderItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const { id } = orderItem
    const url = options.updateAttributes
      ? `/agency/order_items/${id}/update_attributes.json`
      : `/agency/order_items/${id}.json`

    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Order Total
        if (json.order_total){
          dispatch(updateOrderTotal(json.order_id, json.order_total))
        }

        // Normalize
        const normalizedJson = normalize(json, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        // Callback
        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateOrderItemEdition(orderItem, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      order_item: orderItem,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/update_edition.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Update Order Edition
        dispatch(updateOrderEdition(json.order_id, json.publication_edition_id))

        // Normalize
        const normalizedJson = normalize(json, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        // Callback
        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function duplicateOrderItem(orderItem, options){
  const config = {
    method: 'POST',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/order_items/${orderItem.id}/duplicate.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().orderItems.result ? getState().orderItems.result.slice() : []
        if (!resultArray.includes(json.id)){
          resultArray.push(json.id)
        }

        // Update Order Total
        if (json.order_total){
          dispatch(updateOrderTotal(json.order_id, json.order_total))
        }

        // Normalize
        const normalizedJson = normalize(json, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteOrderItem(orderItem, options){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/order_items/${orderItem.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        const resultArray = getState().orderItems.result ? getState().orderItems.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray.splice(index, 1)
        }

        // Update Order Total
        if (json.order_total){
          dispatch(updateOrderTotal(json.order_id, json.order_total))
        }

        // Remove order if it has no items
        if (json.item_count === 0){
          dispatch(removeOrderId(orderItem.order_id))
        }

        // Normalize
        const normalizedJson = normalize(json, Schemas.ORDER_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess(resultArray))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function artworkBuilderConfig(orderItem, options){
  return () => {
    const promise = api(`/agency/order_items/${orderItem.id}/artwork_builder_config.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case SELECT_ORDER_ITEM:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
