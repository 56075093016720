import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'
import api from 'api'

// EXPORTING ACTIONS FOR TEST PURPOSES
export const constants = {
  FETCH_REQUEST: 'realhub/invoice/FETCH_REQUEST',
  FETCH_SUCCESS: 'realhub/invoice/FETCH_SUCCESS',
  FETCH_FAILURE: 'realhub/invoice/FETCH_FAILURE',
  CREATE_REQUEST: 'realhub/invoice/CREATE_REQUEST',
  CREATE_SUCCESS: 'realhub/invoice/CREATE_SUCCESS',
  CREATE_FAILURE: 'realhub/invoice/CREATE_FAILURE',
  UPDATE_REQUEST: 'realhub/invoice/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'realhub/invoice/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'realhub/invoice/UPDATE_FAILURE',
  DELETE_REQUEST: 'realhub/invoice/DELETE_REQUEST',
  DELETE_SUCCESS: 'realhub/invoice/DELETE_SUCCESS',
  DELETE_FAILURE: 'realhub/invoice/DELETE_FAILURE',
  APPROVE_REQUEST: 'realhub/invoice/APPROVE_REQUEST',
  APPROVE_SUCCESS: 'realhub/invoice/APPROVE_SUCCESS',
  APPROVE_ERROR: 'realhub/invoice/APPROVE_ERROR',
}

const {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  APPROVE_REQUEST,
  APPROVE_SUCCESS,
  APPROVE_ERROR,
} = constants

// Initial State
const initialState = {
  approving: false,
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  loadedInvoicesForCampaign: [],
  result: [],
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedInvoicesForCampaign: payload.loadedInvoicesForCampaign,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    payload,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    payload,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function approveRequest(){
  return {
    type: APPROVE_REQUEST,
  }
}

export function approveSuccess(){
  return {
    type: APPROVE_SUCCESS,
  }
}

export function approveFailure(errors = []){
  return {
    type: APPROVE_ERROR,
    errors,
  }
}

const defaultOptions = {
  include_invoice_items: true,
  include_invoice_creditor: true,
  include_invoice_debtor: true,
}

// HELPER FUNCTIONS
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadInvoices(options){
  return (dispatch, getState) => {
    const requestOptions = { ...defaultOptions, ...options }
    const loadedInvoicesForCampaign = getState().invoices.loadedInvoicesForCampaign
      ? getState().invoices.loadedInvoicesForCampaign.slice()
      : []

    if (options.campaign_id){
      const { campaign_id } = options
      const index = loadedInvoicesForCampaign.indexOf(campaign_id)

      if (index === -1){
        loadedInvoicesForCampaign.push(campaign_id)
      }
    }

    dispatch(fetchRequest({ loadedInvoicesForCampaign }))
    const promise = api('/agency/invoices.json', requestOptions)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson.result))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createInvoice(invoice, options = {}, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_id: invoice.campaign_id,
      invoice,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/invoices.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const { id } = json

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess(id))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createInvoiceUsingQuote(campaignQuote, options, callback){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agency/campaign_quotes/${campaignQuote.id}/create_invoice.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const { id } = json

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess(id))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createCustomInvoice(campaign, invoiceItems, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      items: invoiceItems,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agency/campaigns/${campaign.id}/custom_invoice.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const { id } = json

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess(id))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createExternalInvoice(invoice, options = {}, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/invoices/${invoice.id}/create_external_invoice.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateInvoice(invoice){
  const config = {
    method: 'PUT',
    body: JSON.stringify(invoice),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/invoices/${invoice.id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function updateInvoiceTotal(invoiceId, total){
  return (dispatch, getState) => {
    const state = getState()
    const { invoices } = state.entities

    const invoice = invoices[invoiceId]

    if (invoice){
      const updatedInvoice = { ...invoice, total }
      const normalizedJson = normalize(updatedInvoice, Schemas.INVOICE)
      dispatch(updateEntities(normalizedJson))
      dispatch(updateSuccess())
    } else {
      dispatch(updateFailure(['Invoice does not exist.']))
    }
  }
}

export function syncExternalInvoice(invoice, options = {}, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/invoices/${invoice.id}/sync_external_invoice.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function syncExternalInvoices(options = {}, callback){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api('/agency/invoices/sync_external_invoices.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function importExternalInvoice(options, callback){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agency/invoices/import_external_invoice.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function deleteInvoice(invoice){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    const { id } = invoice
    dispatch(deleteRequest())

    const promise = api(`/agency/invoices/${id}.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(deleteSuccess(id))
        dispatch(deleteEntity(normalizedJson))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function approveInvoice(invoice){
  const config = {
    method: 'PUT',
    body: JSON.stringify(invoice),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(approveRequest())

    const promise = api(`/agency/invoices/${invoice.id}/approve.json`, {}, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.INVOICE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(approveFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedInvoicesForCampaign: action.loadedInvoicesForCampaign || state.loadedInvoicesForCampaign,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.payload,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: [...state.result, action.payload],
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: state.result.filter(id => id !== action.payload),
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case APPROVE_REQUEST:
      return { ...state, approving: true }
    case APPROVE_SUCCESS:
      return {
        ...state,
        result: action.payload,
        approving: false,
      }
    case APPROVE_ERROR:
      return {
        ...state,
        errors: action.errors,
        approving: false,
      }
    default:
      return state
  }
}
