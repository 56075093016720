import { normalize } from 'normalizr'
import api from 'api'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

const FETCH_REQUEST = 'realhub/microsite/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/microsite/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/microsite/FETCH_FAILURE'

const CREATE_REQUEST = 'realhub/microsite/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/microsite/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/microsite/CREATE_FAILURE'

const UPDATE_REQUEST = 'realhub/microsite/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/microsite/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/microsite/UPDATE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loading: false,
  creating: false,
  updating: false,
  errors: [],
}

export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
    updating: true,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators

export function createMicrosite(microsite, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      microsite,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agency/order_items/${microsite.order_item_id}/microsites.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MICROSITE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updateMicrosite(microsite, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      microsite,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(
      `/agency/order_items/${microsite.order_item_id}/microsites/${microsite.id}.json`,
      options,
      config,
    )
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.MICROSITE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
      })

    return promise
  }
}

export function checkDomainAvailability(domainName){
  const config = {
    domain: domainName,
  }

  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/integration/domains/availability.json', config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        dispatch(fetchSuccess())

        return json
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
