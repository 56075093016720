const SET_PERMISSIONS = 'realhub/settings/SET_PERMISSIONS'
const SET_DEFAULT_PERMISSIONS = 'realhub/settings/SET_DEFAULT_PERMISSIONS'

// Initial State
const initialState = {
  customizablePermissions: {},
  permissions: {},
}

// Actions
export function hydratePermissions(permissions = {}){
  return {
    type: SET_PERMISSIONS,
    permissions,
  }
}

export function hydrateCustomizablePermissions(permissions = {}){
  return {
    type: SET_DEFAULT_PERMISSIONS,
    permissions,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case SET_PERMISSIONS:
      return { ...state, permissions: action.permissions }
    case SET_DEFAULT_PERMISSIONS:
      return { ...state, customizablePermissions: action.permissions }
    default:
      return state
  }
}
