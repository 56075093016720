import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

import { addImage } from './image'

const FETCH_REQUEST = 'realhub/campaignComparable/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaignComparable/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaignComparable/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/campaignComparable/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/campaignComparable/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/campaignComparable/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/campaignComparable/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/campaignComparable/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/campaignComparable/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/campaignComparable/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/campaignComparable/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/campaignComparable/DELETE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadCampaignComparables(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/campaign_comparables.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_COMPARABLE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createCampaignComparable(campaignComparable, options, callback){
  const config = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      campaign_comparable: campaignComparable,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/campaign_comparables.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().campaignComparables.result ? getState().campaignComparables.result.slice() : []
        if (!resultArray.includes(json.id)){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateCampaignComparable(campaignComparable, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      campaign_comparable: campaignComparable,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agency/campaign_comparables/${campaignComparable.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createComparableFromDataProvider(campaignId, dataProvider, externalPropertyId, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      campaign_id: campaignId,
      data_provider: dataProvider,
      external_property_id: externalPropertyId,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/campaign_comparables/copy_from_data_provider.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteCampaignComparable(campaignComparable, options){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/campaign_comparables/${campaignComparable.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        let resultArray = getState().campaignComparables.result ? getState().campaignComparables.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray = resultArray.splice(index, 1)
        }

        const normalizedJson = normalize(json, Schemas.CAMPAIGN_COMPARABLE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess({ result: resultArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function addCampaignComparableImage(image = {}){
  return (dispatch, getState) => {
    const {
      entities: { campaignComparables },
    } = getState()

    dispatch(addImage(image))

    const campaignComparable = campaignComparables[image.imageable_id] || {}
    const updatedCampaignComparable = {
      ...campaignComparable,
      default_image: image.square_url,
    }

    const normalizedJson = normalize(updatedCampaignComparable, Schemas.CAMPAIGN_COMPARABLE)
    dispatch(updateEntities(normalizedJson))
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    case CREATE_REQUEST:
      return Object.assign({}, state, {
        creating: true,
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        creating: false,
        result: action.result || state.result,
        errors: [],
      })
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case CREATE_FAILURE:
      return Object.assign({}, state, {
        creating: false,
        errors: action.errors,
      })
    case DELETE_REQUEST:
      return Object.assign({}, state, {
        deleting: true,
      })
    case DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleting: false,
        result: action.result,
        errors: [],
      })
    case DELETE_FAILURE:
      return Object.assign({}, state, {
        deleting: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
