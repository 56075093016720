import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/notification/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/notification/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/notification/FETCH_FAILURE'
const UPDATE_REQUEST = 'realhub/notification/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/notification/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/notification/UPDATE_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  result: [],
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(payload = {}){
  return {
    type: UPDATE_SUCCESS,
    result: payload.result,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadNotifications(options){
  return (dispatch, getState) => {
    const { notifications } = getState()
    const { location } = options

    const loadedForKeys = [...notifications.loadedForKeys]
    if (!loadedForKeys.includes(location)){
      loadedForKeys.push(location)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/notifications.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.NOTIFICATION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(normalizedJson))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function readNotification(notification, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      v2: true,
      notification,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/notifications/${notification.id}/mark_read.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.NOTIFICATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
