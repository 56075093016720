import KeenTracking from 'keen-tracking'

const SET_CLIENT = 'realhub/analytics/eventTracker/SET_CLIENT'

// Initial State
const initialState = {
  client: null,
}

// Actions
export function setClient(client){
  return {
    type: SET_CLIENT,
    client,
  }
}

// Action Creators
export function initializeClient(config){
  // Setup Client
  const client = new KeenTracking(config)

  return (dispatch) => {
    dispatch(setClient(client))

    return client
  }
}

export function recordEvent(key, eventData, options = {}){
  return (dispatch, getState) => {
    const { client } = getState().analytics.eventTracker
    if (client){
      client.recordEvent({
        collection: key,
        event: eventData,
        requestType: options.useBeaconAPI ? 'beaconAPI' : 'fetchAPI',
      })
    }
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case SET_CLIENT:{
      return {
        ...state,
        client: action.client,
      }
    }
    default:
      return state
  }
}
