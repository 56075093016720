import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/publicationEdition/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/publicationEdition/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/publicationEdition/FETCH_FAILURE'

const SELECT_EDITION = 'realhub/publicationEdition/SELECT_EDITION'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  loaded: false,
  loading: false,
  loadedEditionKeys: [],
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loading: true,
    loadedEditionKeys: payload.loadedEditionKeys,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}
export function selectEdition(publicationEdition = {}){
  return (dispatch) => {
    dispatch({
      type: SELECT_EDITION,
      selectedId: publicationEdition.id,
    })
  }
}

// Action Creators
export function loadPublicationEditions(publicationId, options, callback){
  return (dispatch, getState) => {
    const editions = getState().publicationEditions

    // Set Loading
    const publicationKey = `Publication${publicationId}`
    const loadedEditionKeys = editions.loadedEditionKeys ? editions.loadedEditionKeys.slice() : []
    if (!loadedEditionKeys.includes(publicationKey)){
      loadedEditionKeys.push(publicationKey)
    }
    dispatch(fetchRequest({ loadedEditionKeys }))

    const promise = api(`/agency/publications/${publicationId}/editions.json`, options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.PUBLICATION_EDITION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)
      })
      .catch((json) => {
        const errors = json.errors || ['Something went wrong']
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        loadedEditionKeys: action.loadedEditionKeys || state.loadedEditionKeys,
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      })
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors,
      })
    case SELECT_EDITION:
      return Object.assign({}, state, {
        selectedId: action.selectedId,
      })
    default:
      return state
  }
}
