import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities, deleteEntity } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/order/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/order/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/order/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/order/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/order/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/order/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/order/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/order/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/order/UPDATE_FAILURE'
const DELETE_REQUEST = 'realhub/order/DELETE_REQUEST'
const DELETE_SUCCESS = 'realhub/order/DELETE_SUCCESS'
const DELETE_FAILURE = 'realhub/order/DELETE_FAILURE'

const APPROVE_REQUEST = 'realhub/order/APPROVE_REQUEST'
const APPROVE_SUCCESS = 'realhub/order/APPROVE_SUCCESS'
const APPROVE_FAILURE = 'realhub/order/APPROVE_FAILURE'

const SELECT_ORDER = 'realhub/order/SELECT_ORDER'

// Initial State
const initialState = {
  result: [],
  selectedId: null,
  loaded: false,
  loadedForEntities: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  approving: false,
  errors: [],
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForEntities: payload.loadedForEntities,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(payload = {}){
  return {
    type: DELETE_SUCCESS,
    result: payload.result,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function approveRequest(){
  return {
    type: APPROVE_REQUEST,
  }
}

export function approveSuccess(){
  return {
    type: APPROVE_SUCCESS,
  }
}

export function approveFailure(errors = []){
  return {
    type: APPROVE_FAILURE,
    errors,
  }
}

export function selectOrder(orderId){
  return (dispatch, getState) => {
    const state = getState()
    const { orders } = state.entities

    const selectedOrder = orders[orderId]

    dispatch({
      type: SELECT_ORDER,
      selectedId: orderId,
      selected: selectedOrder,
    })
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadOrders(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/orders.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadEntityOrders(entityKey, options){
  return (dispatch, getState) => {
    const { orders } = getState()
    const loadedForEntities = orders.loadedForEntities ? orders.loadedForEntities.slice() : []
    if (!loadedForEntities.includes(entityKey)){
      loadedForEntities.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForEntities }))

    const promise = api('/agency/orders.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createOrder(order, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      order,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    // Encode Params
    const promise = api('/agency/orders.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().orders.result ? getState().orders.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createMultipleOrders(order, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      order,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/agency/orders/create_multiple.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createOrderWithOrderItem(orderItem, options){
  const config = {
    method: 'POST',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/orders/${orderItem.order_id}/reorder_item/${orderItem.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().orders.result ? getState().orders.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function createOrderWithFavouriteOrder(favouriteOrder, options, callback){
  const config = {
    method: 'POST',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(createRequest())

    const url = `/agency/favourite_orders/${favouriteOrder.id}/create_order.json`
    const promise = api(url, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const resultArray = getState().orders.result ? getState().orders.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index === -1){
          resultArray.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: resultArray }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function addItemToOrder(existingOrderId, order, options, callback){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      order,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api(`/agency/orders/${existingOrderId}/item.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess({ result: normalizedJson.result }))

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateOrder(order, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      order,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/agency/orders/${order.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateOrderEdition(orderId, publicationEditionId){
  return (dispatch, getState) => {
    const state = getState()
    const { orders } = state.entities

    const order = orders[orderId]

    if (order){
      const updatedOrder = { ...order, publication_edition_id: publicationEditionId }
      const normalizedJson = normalize(updatedOrder, Schemas.ORDER)
      dispatch(updateEntities(normalizedJson))
    } else {
      dispatch(updateFailure(['Order does not exist.']))
    }
  }
}

export function updateOrderTotal(orderId, total){
  return (dispatch, getState) => {
    const state = getState()
    const { orders } = state.entities

    const order = orders[orderId]

    if (order){
      const updatedOrder = { ...order, total }
      const normalizedJson = normalize(updatedOrder, Schemas.ORDER)
      dispatch(updateEntities(normalizedJson))
      dispatch(updateSuccess())
    } else {
      dispatch(updateFailure(['Order does not exist.']))
    }
  }
}

export function deleteOrder(order, options, callback){
  const config = {
    method: 'DELETE',
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(deleteRequest())

    const promise = api(`/agency/orders/${order.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Remove from result array
        const resultArray = getState().orders.result ? getState().orders.result.slice() : []
        const index = resultArray.indexOf(json.id)
        if (index !== -1){
          resultArray.splice(index, 1)
        }

        // Update related orders
        if (json.updated_orders){
          const normalizedUpdated = normalize(json.updated_orders, Schemas.ORDER_ARRAY)
          dispatch(updateEntities(normalizedUpdated))
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess(resultArray))

        if (callback) callback(json)

        return json
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(deleteFailure(errors))
      })

    return promise
  }
}

export function removeOrderId(orderId){
  return (dispatch, getState) => {
    const state = getState()
    const { orders } = state.entities

    const order = orders[orderId]

    // Remove from result array
    const resultArray = getState().orders.result ? getState().orders.result.slice() : []
    const index = resultArray.indexOf(orderId)
    if (index !== -1){
      resultArray.splice(index, 1)
    }

    if (order){
      const normalizedJson = normalize(order, Schemas.ORDER)
      dispatch(deleteEntity(normalizedJson))
      dispatch(deleteSuccess(resultArray))
    } else {
      dispatch(deleteFailure(['Order does not exist.']))
    }
  }
}

export function approveOrder(order, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      order,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(approveRequest())

    const promise = api(`/agency/orders/${order.id}/approve.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(approveSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(approveFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function unapproveOrder(order, options, callback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      order,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(approveRequest())

    const promise = api(`/agency/orders/${order.id}/unapprove.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(approveSuccess())

        if (callback) callback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(approveFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

export function payAndApproveOrder(order, stripeToken, fee, options, successCallback, errorCallback){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      stripe_token: stripeToken,
      card_fee: fee,
      order,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(approveRequest())

    const promise = api(`/agency/orders/${order.id}/pay_and_approve.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.ORDER)
        dispatch(updateEntities(normalizedJson))
        dispatch(approveSuccess())

        if (successCallback) successCallback(json)

        return { success: true, result: json }
      })
      .catch((json) => {
        if (errorCallback) errorCallback(json)

        const errors = handleError(json.errors)
        dispatch(approveFailure(errors))
        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForEntities: action.loadedForEntities || state.loadedForEntities,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return { ...state, deleting: true }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        result: action.result,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case APPROVE_REQUEST:
      return { ...state, approving: true }
    case APPROVE_SUCCESS:
      return {
        ...state,
        approving: false,
        errors: [],
      }
    case APPROVE_FAILURE:
      return {
        ...state,
        approving: false,
        errors: action.errors,
      }
    case SELECT_ORDER:
      return { ...state, selectedId: action.selectedId }
    default:
      return state
  }
}
